import React, { useState } from 'react';
import cx from 'classnames';
import $ from 'jquery';
import * as queries from 'seed/gql/queries'
import { useQuery, useDetail, useDelete } from 'seed/gql'
import { usePost } from 'seed/api'
import RegionSelect from 'react-region-select';
import { Loading } from 'seed/helpers'
import Region from 'components/scan/selection/Region';
import Regions from 'components/scan/selection/Regions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from 'styles/css/scan/selection/Selection.module.css';
import { getRegions } from 'components/scan/selection/Selection.lcore'
import { Document, Page, pdfjs } from 'react-pdf';
import
{
  DOCUMENT, PAGES, BANKS, REGIONS,
  loadRegions, getScanRegions
} from 'components/scan/selection/Selection.lqueries'
import { getSuggestions, getTableAttr, getFormatAttr, getPageText, getValidRegions, getDefFormat } from 'components/scan/selection/Selection.lutil'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function Selection(props)
{
  const { document_id, declaration_id } = props.match.params;
  const [state, setState] = useState({ pages: {} });
  const [optionMenu, setOptionMenu] = useState(null);
  const [defBank, setDefBank] = useState(0)
  const [defFormat, setDefFormat] = useState(0)
  const [suggestionIdxs, setSuggestionIdxs] = useState({})
  const [pageWidth, setPageWidth] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [autoLoading, setAutoLoading] = useState(false)

  const qDocument = useDetail(`{
    document {
      file {
        name
        url
      }
    }
  }`, document_id)
  const qBanks = useQuery(BANKS);
  const qPages = useQuery(PAGES, `document.id=${document_id}`);
  const qRegions = useQuery(REGIONS, `page.document.id=${document_id}`, {
    onCompleted: data =>
      setState({ pages: loadRegions(data).pages })
  });

  const [callDelete] = useDelete(queries.DELETE_REGION,  
    { onCompleted: () => qRegions.refetch() })

  const [callScan] = usePost("/documents/scan", {
      onCompleted: () => {
        qRegions.refetch();
        setAutoLoading(false);
      },
      onError: data => alert("Ha ocurrido un error, prueba de nuevo")
    })

  if (qDocument.loading || qPages.loading || qRegions.loading || qBanks.loading)
    return <div className={styles.loading}><Loading /></div>
  if (qPages.error || qRegions.error || qBanks.error)
    return `Error! ${qPages.message}`;

  const { document } = qDocument.data;
  const { pages = [] } = qPages.data;
  const { banks = [] } = qBanks.data;
  const { regions = [] } = qRegions.data;
  let banksSorted = [...banks]
  let pagesSorted = [...pages]
  banksSorted.sort((b1, b2) => ('' + b1.name).localeCompare(b2.name));
  pagesSorted.sort((a, b) => a.id - b.id)

  if (defBank == 0 && defFormat == 0) {
    const format = getDefFormat(pagesSorted, banks)
    if (format != null) {
      setDefBank(format.defBank);
      setDefFormat(format.defFormat);
    }
  }

  const onSend = () =>
  {
    const pages = state.pages;
    const regions = getScanRegions(pagesSorted);
    for (let region of regions)
      if (region.id != null) callDelete({ id: region.id })
    callScan({ regions: regions, declaration_id: declaration_id })
  }

  const onDeleteRegion = (regionId) =>
    callDelete({ id: regionId });

  const onClickAuto = () =>
  {
    setAutoLoading(true)
    for (let region of regions)
      callDelete({ id: region.id });
    setTimeout(autoSelect, 1000);
  }

  const autoSelect = () =>
  {
    let body = []
    const defs = getDefFormat(pagesSorted, banks);
    let defFormat = defs.defFormat;
    let format = getFormatAttr(banks, defFormat).format;
    for (let table of format.tables) {
      const regions = getRegions(pagesSorted, table);
      for (let region of regions) {
        body.push({
          x: region.x,
          y: region.y,
          width: region.width,
          height: region.height,
          page_id: region.pageId,
          table_id: region.tableId
        })
      }
    }
    callScan({ regions: body, declaration_id: declaration_id});
  }


  const onSelectRegion = (pageId, regions) =>
  {
    const pages = state.pages;
    const res = getValidRegions(regions);
    pages["p" + pageId] = res;
    setState({ pages: pages });
  }

  const onDeleteLocalRegion = (pageId, regionIdx) =>
  {
    const page = state.pages["p" + pageId];
    page.splice(regionIdx, 1);
    setState({ pages: state.pages });
  }

  const onSelectTable = (pageId, regionIdx, tableId) =>
  {
    const tableAttr = getTableAttr(banks, tableId);
    let page = state.pages["p" + pageId];
    let region = page[regionIdx]
    region.pageId = pageId;
    region.tableId = tableAttr.table.id;
    setState({ pages: state.pages });
    setDefFormat(tableAttr.format.id)
    setDefBank(tableAttr.bank.id)
  }

  const onSelectBank = formatId =>
  {
    const formatAttr = getFormatAttr(banks, formatId);
    setDefFormat(formatAttr.format.id)
    setDefBank(formatAttr.bank.id)
  }

  const openOptionMenu = e =>
    setOptionMenu(e.currentTarget);

  const closeOptionMenu = () =>
    setOptionMenu(null);

  const onClickGo = suggestion =>
  {
    let regionIdx = 0;
    for (let ps in state.pages)
      regionIdx += state.pages[ps].length;
    if (suggestion.regions.length == 0) {
      alert("No se encontraron coincidencias, prueba de nuevo");
      return;
    }

    let suggestionIdx = suggestionIdxs[suggestion.tableId] ? suggestionIdxs[suggestion.tableId] : 0;
    if (suggestionIdx == suggestion.regions.length)
      suggestionIdx = 0;
    let region = suggestion.regions[suggestionIdx];
    const scan = $("." + styles.scan)[0];
    const currentScroll = scan.scrollTop
    const offset = region.y * region.page.height() / 100;
    scan.scrollTo(0, region.page.offset().top - 180 + offset + currentScroll);

    region.data.index = regionIdx;

    let pagess = state.pages;
    if (pagess["p" + pagesSorted[region.pageIdx].id] == null)
      pagess["p" + pagesSorted[region.pageIdx].id] = []
    let regionss = pagess["p" + pagesSorted[region.pageIdx].id];
    regionss.push(region)
    pagess["p" + pagesSorted[region.pageIdx].id] = getValidRegions(regionss);
    setState({ pages: pagess });

    suggestionIdxs[suggestion.tableId] = suggestionIdx + 1;
    setSuggestionIdxs(suggestionIdxs);
  }


  const onGoTo = (regionIdx) =>
  {
    for (let p in state.pages) {
      let page = state.pages[p];
      let pIdx = 0;
      for (let i = 0; i < pagesSorted.length; i++)
        if (pagesSorted[i].id == parseInt(p.substring(1))) {
          pIdx = i;
          break;
        }

      for (let region of page) {
        if (region.data.index == regionIdx) {
          const pagei = $(`#page_${pIdx}`);
          const scan = $("." + styles.scan)[0];
          const currentScroll = scan.scrollTop
          const offset = region.y * pagei.height() / 100;
          scan.scrollTo(0, pagei.offset().top - 180 + offset + currentScroll);
          return;
        }

      }
    }
  }

  const getFileName = name =>
      name.length >= 32 ? name.substring(33, name.length) : name

  const onLoadSuccess = () =>
  {
    setPageWidth($(`.${styles.imageWrapper}`).width());
    setSuggestions(getSuggestions(banks, pagesSorted, defFormat));
  }

  const regionRenderer = (page, regionProps) =>
    <Region
      regionProps={regionProps}
      defBank={defBank}
      defFormat={defFormat}
      region={state.pages["p" + page][regionProps.index]}
      banks={banks}
      page={page}
      onSelectTable={onSelectTable}
      onDeleteRegion={onDeleteRegion}
      onDeleteLocalRegion={onDeleteLocalRegion} />

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Escaneo</h4>
      </div>

      <div className={styles.content} style={{ height: ($(window).height() - 100) + "px" }}>
        <div className={styles.scan}>
          <div className={styles.notice}>
            Selecciona las tablas a escanear <span>|</span>
            <select value={defFormat}
              onChange={(e) => onSelectBank(e.target.value)}>
              <option>Selecciona un banco</option>
              {
                banksSorted.map(bank =>
                    bank.formats.map(format =>
                      <option key={format.id}
                        value={format.id}>{bank.name + " " + format.name}</option>)
                  )
              }
              
          </select>
            {<button className={cx(styles.btn, styles.toRigth, styles.auto)}
              onClick={onSend}>Escaneo Manual</button>}
            <button className={cx(styles.btn, styles.btnGreen, styles.toRigth, styles.save)}
              onClick={onClickAuto}>Re - Escanear {autoLoading ? "..." : ""}</button>
            {
              defFormat != 0 ?
                <div className={cx(styles.missing)}>
                  Tablas identificadas <i>({getFileName(document.file.name).length >= 20 ? getFileName(document.file.name).substring(0,20) + "….pdf" : getFileName(document.file.name)})</i><br />
                  {
                    suggestions.map(suggestion =>
                      <div>
                        <span className={styles.missingItem}>{suggestion.name}</span>
                        {suggestion.regions.length > 0 ?
                          <div className={styles.goContainer}>
                            <div className={styles.go}
                              onClick={() => onClickGo(suggestion)}>Ir 
                               <span className={styles.count}>
                              {suggestionIdxs[suggestion.tableId] ? suggestionIdxs[suggestion.tableId] : 0}/
                              {suggestion.regions.length}
                            </span>
                              <i className="fas fa-chevron-down"></i>
                            </div>
                          </div> : null
                        }

                      </div>)
                  }
                </div> : null}
            <div
              onClick={openOptionMenu}
              className={styles.options}>
              <i className={cx("fas fa-ellipsis-v")}></i>
            </div>
            <Menu
              anchorEl={optionMenu}
              open={Boolean(optionMenu)}
              onClose={closeOptionMenu}>
              {/*<MenuItem>Reescanear</MenuItem>*/}
              <MenuItem><a
                style={{ color: "inherit", padding: "0px", margin: "0px" }}
                download="logs.txt"
                href={`data:text/plain;utf-8,${getPageText(pages)}`}>Logs</a></MenuItem>

            </Menu>
          </div>
          <div>
            {
            <Document
              file={document.file.url}
              onLoadSuccess={onLoadSuccess}
              className={styles.imageWrapper}>
              {
                pagesSorted.map((p, idx) =>
                    <div
                      key={idx}
                      className={styles.imageBorder}>
                      <RegionSelect
                        key={idx}
                        maxRegions={100}
                        className={styles.imageWrapper}
                        onChange={regions => onSelectRegion(p.id, regions)}
                        regions={"p" + p.id in state.pages ? state.pages["p" + p.id] : []}
                        regionRenderer={(regionProps) => regionRenderer(p.id, regionProps)}>

                        <div id={`page_${idx}`}>
                          <Page
                            width={pageWidth}
                            renderMode={"canvas"}
                            renderTextLayer={false}
                            renderInteractiveForms={false}
                            renderAnnotationLayer={false}
                            className={cx(styles.image, styles.image + "_" + p.id)}
                            pageNumber={idx + 1} />
                        </div>

                      </RegionSelect>
                    </div>)
              }
            </Document>
            }
          </div>
        </div>
        <Regions
          {...props}
          onGoTo={onGoTo}
          loading={autoLoading}
          regions={regions}
          document={document} />

      </div>
    </div>
  );
}

export default Selection;