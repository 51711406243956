import React from 'react';
import cx from 'classnames';
import styles from 'styles/css/auth/Login.module.css';
import { API_URL } from 'settings';

function Login(props) {
  
  const redirect_url = API_URL + "/users/azure_auth/"
  const redirect_href = "https://login.microsoftonline.com/5a3c2a26-df13-4524-9df2-07d9a4d4a7d0/oauth2/v2.0/authorize?client_id=a2852a12-bbfc-48e0-b694-999138377929&response_type=code&response_mode=query&scope=openid%20User.Read&prompt=select_account&state=" + encodeURI("crz-link-" + redirect_url);

  return (
    <div className={styles.module}>
      <div className={styles.background}>

        <img
          className={styles.logo}
          src={"/resources/images/logo.png"}
          alt="Logo" />

      <div className={styles.dots} />
      <div className={cx(styles.container, 'animated zoomIn')}>

        <label className={styles.title}>Inicio de sesión<br/><span></span></label>
        <a href={redirect_href} className={styles.submit}>Iniciar sesión con Cuenta de Microsoft</a>
        
      </div>

      </div>
    </div>
  );
}

export default Login;
