import React, { useState } from 'react';
import cx from 'classnames';
import { screenSize } from 'components/util/Format'
import { useCount } from 'seed/gql'
import List from 'components/documents/List'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/documents/Pages.module.css';


function Pages(props) {

  const { filterBank = 0 } = props;
  const { declaration_id } = props.match.params;
  const [pageNum, setPageNum] = useState(1);
  let query = `declaration=${declaration_id}`
  if (filterBank > 0) query += ` AND pages.regions.table.format.bank=${filterBank}`
  const qCount = useCount("document", query)
  if (qCount.loading) return <Loading />

  const count = qCount.data && filterBank != -1 ? qCount.data.documentCount.count : 0;

  const onClickPage = i => {
    setPageNum(i);
    localStorage.setItem("page_scan", i)
  }

  const pageSize = 20;
  const pageCount = Math.ceil(count / pageSize);
  let pages = [];
  if (pageCount < 1 && pageCount > 0) onClickPage(1)

  for (let i = 1; i <= pageCount; i++)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(i)}
        style={{ background: pageNum == i ? "#f5f5f5" : "#fff" }}>{i}</div>)


  return (
    <div className={styles.module}>

      <div className={styles.content}
        style={{ height: (screenSize() - 250) + "px" }}>
        <List {...props}
          pageNum={pageNum}
          filterBank={filterBank} />
      </div>
      <div className={styles.pages}>
        {pages}
      </div>
    </div>
  );
}

export default Pages;