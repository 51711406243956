import React, { useState } from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import { useQuery } from 'seed/gql';
import { Formik } from 'formik';


import { Loading } from 'seed/helpers';
import { FileField } from 'seed/helpers';
import { BANKS } from 'components/scan/selection/Selection.lqueries';
import { getRegions } from 'components/scan/selection/Selection.lcore';
import { getFormatAttr, getDefFormat } from 'components/scan/selection/Selection.lutil';

import styles from 'styles/css/documents/Upload.module.css';


function DocumentForm(props) {

  const { declaration_id } = props.match.params;
  const qBanks = useQuery(BANKS);
  const [type, setType] = useState('STATEMENT')
  const [fileStatus, setFileStatus] = useState([false])
  const [uploadMessage, setUploadMessage] = useState("")
  const [filesLoading, setFilesLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const { banks = [] } = qBanks.data;


  const onUploadEC = (data) => {
    let body = []
      let errors = []
      for (let pages of data) {
        if (pages.length > 0 && pages[0].status == "error") {
          errors.push(pages[0].file.substring(33, pages[0].file.length));
          continue;
        }
        const defs = getDefFormat(pages, banks);
        if (defs == null) continue;
        let defFormat = defs.defFormat;
        let format = getFormatAttr(banks, defFormat).format;
        for (let table of format.tables) {
          const regions = getRegions(pages, table);
          for (let region of regions) {
            body.push({
              x: region.x,
              y: region.y,
              width: region.width,
              height: region.height,
              page_id: region.pageId,
              table_id: region.tableId
            })
          }
        }
      }
      let message = "Carga completa";
      if (errors.length > 0) message += "\n\nError al cargar:";
      for (let error of errors) message += `\n - ${error}`
      setUploadMessage(message)
      return body
  }

  const onMonitor = (data, remonitor) => {
    if (data.status == "IN_PROCESS"){
      setLoading(true)
      setTimeout(remonitor, 5000)
      return
    }
    setLoading(false)
    if (data.status == "OK"){
      if (data.type == "UPLOAD_DOC"){
        let body = onUploadEC(data.result["data"])
        callScan({ declaration_id: declaration_id, regions: body });
      }
      if (data.type == "SCAN_DOC"){
        alert(uploadMessage);
        window.location.href = "/app/scan";
      }
    } else
      alert("Ha ocurrido un error, prueba de nuevo")
  }


  const [cMonitorB, qMonitorB] = usePost("/processes/status", {
    onCompleted: data => onMonitor(data, () => cMonitorB({uuid: data.uuid})),
    onError: data => alert("Ha ocurrido un error, prueba de nuevo")
  });

  const [callScan, qScan] = usePost("/documents/scan", {
    onCompleted: data => cMonitorB({uuid: data.uuid}),
    onError: () => alert("Error escaneando documentos")
  });

  const [cMonitor, qMonitor] = usePost("/processes/status", {
    onCompleted: data => onMonitor(data, () => cMonitor({uuid: data.uuid})),
    onError: data => alert("Ha ocurrido un error, prueba de nuevo")
  });

  const [callPostDoc, qPostDoc] = usePost("/documents/upload", {
    onCompleted: data => cMonitor({uuid: data.uuid}),
    onError: () => alert("Error cargando documentos")
  });

  
  if (loading)
    return (
      <div className={styles.loading}>
        <Loading />
        <div className={styles.status}>Procesando...</div>
      </div>)

  const onSubmit = values => {
    let bodies = [];
    for (let files in fileStatus) {
      for (let file of values["file_" + files]) {
        let body = {}
        body.source = "MANUAL";
        body.status = "SENT";
        body.type = type;
        body.file_id = parseInt(file.id);
        body.declaration_id = parseInt(declaration_id);
        bodies.push(body)
      }
    }
    callPostDoc({ declaration_id: declaration_id, bodies: bodies })
  }

  const onChangeType =
    e => setType(e.target.value);

  const onNewDoc = () => {
    let status = fileStatus.concat([false]);
    setFileStatus(status);
  }

  const onRemoveDoc = idx => {
    let status = fileStatus.concat([]);
    status.splice(idx, 1);
    setFileStatus(status);
  }

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Subir documentos</h4>
      </div>

      <div className={styles.paper, styles.form}>

        <label className={cx(styles.lbl, styles.fileLbl)}>Tipo de documento</label><br />
        <select onChange={onChangeType}>
          <option value="STATEMENT">Estado de cuenta (Formato PDF)</option>
          {/*<option value="MASTRO">Mastro (Formato XLS - Excel)</option>*/}
        </select>
        <br />
        <br />

        <Formik
          initialValues={document}
          onSubmit={onSubmit}
          render={f => (

            <form onSubmit={f.handleSubmit}>
              {
                fileStatus.map((fl, idx) =>
                  <div>
                    <FileField name={`file_${idx}`}
                      accept={"application/pdf"}
                      setFieldValue={f.setFieldValue}
                      setLoading={(loading) =>{ filesLoading[idx] = loading;  setFilesLoading(filesLoading);}}
                      multiple={true}
                      className={cx(styles.fil, styles.fileFil)} />
                    { filesLoading[idx] !== undefined && filesLoading[idx] == true ?  <div><Loading /></div> : null}
                    {idx == fileStatus.length - 1 && idx > 0 ? <i className={cx(styles.remove, "fas", "fa-minus-circle")}
                      onClick={() => onRemoveDoc(idx)}></i> : null}
                    <br />
                  </div>)
              }

              <button type="button" className={cx(styles.btn, styles.btnRegular, styles.fullBtn, styles.newDoc)}
                onClick={onNewDoc}>Subir más archivo</button>

              <br />
              <br />
              {(() => {
                let show = true;
                for (let idx in fileStatus)
                  if (f.values["file_" + idx] == null || filesLoading[idx] == true) show = false;
                return show ? <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>Guardar documento(s)</button> : <button type="button" className={cx(styles.btn, styles.btnDisabled, styles.fullBtn)}>Guardar documento(s)</button>
              })()
              }
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default DocumentForm;
