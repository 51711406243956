import React from 'react';
import cx from 'classnames';
import { Link, Route } from 'react-router-dom'
import { Modal } from 'seed/helpers';
import Download from 'components/scan/selection/Download';
import { Loading } from 'seed/helpers';
import styles from 'styles/css/scan/selection/Selection.module.css';


function Region(props)
{
  const { url, path } = props.match;
  const { regions, document } = props;
  let regionsSorted = [... regions];

  let tableMap = {}
  regionsSorted.map((r, idx) =>
    {
      const tableId = r.table.id;
      if (tableMap[tableId] == null)
        tableMap[tableId] = {
          id: tableId,
          title: r.table.name,
          subtitle: r.table.format.bank.name + " " + r.table.format.name,
          idxs: []
        }
      tableMap[tableId].idxs.push(idx);
    });

  let tables = [];
  for (let t in tableMap)
    tables.push(tableMap[t]);

    const DownloadModal = props =>
    <Modal width={280} height={200}{...props} >
      <Download {...props} />
    </Modal>

  return (
    <div className={styles.regions}>
      <div>
        <h5 style={{ paddingLeft: "18px" }}>Registros</h5>
        {/*<Link to={`${url}/document/${document.id}`} className={cx(styles.download, styles.btn, styles.btnOutline)}>
          Descargar <i className="fas fa-download"></i>
        </Link>*/}
      </div>
      <hr />
      {props.loading ? <Loading /> : null}
      <div className={styles.list}>
        {tables
          .map((t, idx) =>
            <div className={styles.item} key={t.id}>
              <div className={styles.info}>
                <p className={styles.name}>
                  {t.title}
                </p>
                <p className={styles.date}>
                  {t.idxs.map(idx => <button className={styles.numId} onClick={() => props.onGoTo(idx)}>{idx + 1}</button>)}
                  {t.subtitle}</p>
              </div>
              <div className={styles.toRigth}>
                <Link to={`${url}/${t.id}`} className={cx(styles.btn, styles.see, styles.toRigth)}>
                  <span className={styles.text}>Ver</span>
                </Link>
              </div>
            </div>
          )
        }
      </div>

      <Route path={[
        `${path}/:type(declaration|document)/:id(\\d+)`]}
        component={DownloadModal} />

    </div>
  );
}

export default Region;