import React from 'react';
import { useQuery, usePagination} from 'seed/gql'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/transactions_nac/Table.module.css';
import queryString from 'query-string';

import { getNumFormat, getDateFormat } from 'components/transactions_nac/Table.lutil';


const MOVCASHES = `
{
  movcashNacPagination {
    totalPages
    movcashNacs {
      idx
      uid
      currency
      issuer
      rfc
      documentNum
      typeMn
      concept
      date
      amount
      notes
    }
  }
}
`

function Table(props) {

  const { url } = props.match;
  const { pageNum = 1, filter = "" } = props;
  const { month = "", symbol = "", accountNumber = "", currency = "" } = queryString.parse(props.location.search);
  const { declaration_id } = props.match.params;
  const pageSize = 150;
  const movcashQuery = `declaration=${declaration_id}` +
  (filter != "" ? ` AND accountNumber=${filter}` : "") +
  (accountNumber != "" ? ` AND accountNumber=${accountNumber}` : "") +
  (currency != "" ? ` AND originalCurrency=${currency}` : "") +
  (symbol != "" ? ` AND symbolCusip=${symbol}` : "")
  const qMovcashes = usePagination(MOVCASHES, pageNum, pageSize, movcashQuery, { orderBy: "idx" })


  if (qMovcashes.loading) return <div className={styles.loading}><Loading /></div>
  if (qMovcashes.error) return `Errorr! ${qMovcashes.error}`;

  let { movcashNacs = [] } = qMovcashes.data.movcashNacPagination;

  let res = [];
  let headerData = ["Concepto", "Fecha", "Monto", "Moneda",  "Emisora", "RFC", "# Documento", "Tipo Sistema",  "Notas"]
  let attrs = [ "concept", "date", "amount", "currency",  "issuer", "rfc", "documentNum",  "typeMn", "notes"]
  let movFilter = [...movcashNacs]
    .filter(m => filter == "" || (m.rfc == filter))
    .filter(m => {
      if (month == "") return true;
      try {
        let dateStr = m.date;
        let yearI = parseInt(dateStr.split("/")[2]);
        let monthI = parseInt(dateStr.split("/")[1]);
        let keyI = yearI + "" + ("00" + monthI).slice(-2);
        return month == keyI;
      } catch (e) {
        return false;
      }
    })
    .sort((f1, f2) => f1.idx - f2.idx)

  for (let movcash of movFilter) {
    let row = [];
    for (let attr of attrs) {
      let value = movcash[attr];
      if (attr == "amount")
        value = getNumFormat(value)
      if (attr == "date")
        value = getDateFormat(value)
      if (attr == "type")
        value = value.length > 25 ? value.substring(0, 25) + "…" : value
      if (attr == "issuer" || attr == "notes")
        value = value.length > 35 ? value.substring(0, 35) + "…" : value
      if ( attr == "concept")
        value = value.length > 50 ? value.substring(0, 50) + "…" : value
     
       row.push({
        value: value, attr: attr, id: movcash.id,
        documentId: 0, docName: "", tableId: 0, tableName: "", regionId: 0, row: 0
      })
    }
    res.push(row)
  }

  // GROUPING

  let documents = {};
  for (let r of res) {
    if (documents[r[0].docName] == null)
      documents[r[0].docName] = [];
    documents[r[0].docName].push({
      docName: r[0].docName,
      documentId: r[0].documentId,
      data: r
    });
  }

  let grouped = [];
  for (let r in documents)
    grouped.push(documents[r]);


  return (
    <div className={styles.module}>

      <div className={styles.content}>

        <div className={styles.table}>

          <table className={styles.header}>
            <tr>
              {headerData.map((header, idx) => <th key={idx}>{header}</th>)}
            </tr>
          </table>
          {
            grouped.map((doc, idxd) =>
              <div>
                <label style={{ marginTop: idxd == 0 ? "15px" : "25px" }}></label><br />
                {
                  <table>
                    {
                      doc.map(
                        (table, idx) =>
                          <tr key={idx}>
                            {table.data.map((col, idxc) =>
                              <td>{col.value}</td>
                            )}
                          </tr>)
                    }
                  </table>
                }

              </div>
            )
          }

        </div>

      </div>
    </div>
  );
}

export default Table;