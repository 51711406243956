import React from 'react';
import cx from 'classnames';
import queryString from 'query-string';
import { SERVER_URL } from 'settings';
import { useQuery } from 'seed/gql';
import styles from 'styles/css/documents/Formats.module.css';
import { Loading } from 'seed/helpers';


const FORMATS = `
{
  formats {
    name
    bank {
      id
      name
    }
  }
}
`

function Formats(props) {
  
  const { status } = queryString.parse(props.location.search)
  let message = "";
  const qFormats = useQuery(FORMATS, "", {orderBy: "id"})
  if (status == "NO_REGISTRY")
    message = "En caso de aparecer 'SIN REGISTROS' es posible que el estado de cuenta no tenga operaciones o no sea un FORMATO COMPATIBLE";

  if (qFormats.loading) return <Loading />
  
  const { formats = []} = qFormats.data

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Formatos</h4>
      </div>

      <div className={styles.paper}>
        {
          message != "" ? <div className={styles.message}>{message}</div> : null
        }
        <div className={styles.title}>Bancos compatibles</div>
        <div>Los formatos por banco pueden variar</div>
        <div className={styles.list}>
          {
            formats
              .map((format, idx) =>
                <div className={styles.item} key={idx}>
                  <div className={styles.info}>
                    <p className={styles.name}>{format.bank.name + " " + format.name}</p>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default Formats;
