const REGIONS = `
{
  regions {
    x
    y
    width
    height
    page {
      refIndex
      width
      height
      document{
        file {
          url
        }
      }
    }
    table {
      columns {
        index
        name
      }
    }
  }
}`

const CELLS = `
{
  cells {
    value
    row
    x
    y
    width
    height
    col {
      index
    }
    region {
      page{
        refIndex
      }
    }
  }
}
`

export { REGIONS, CELLS };