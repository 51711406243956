import { getRegions, getConditionalText } from 'components/scan/selection/Selection.lcore'
import $ from 'jquery';

const getSuggestions = (banks, pages, defFormat) =>
{
  if (defFormat == 0) return []
  let format = getFormatAttr(banks, defFormat).format;
  let suggestions = {};
  for (let table of format.tables) {
    if (suggestions[table.id] == null)
      suggestions[table.id] = {
        name: table.name.length > 48 ? table.name.substring(0, 48) + "…" : table.name,
        tableId: table.id,
        regions: []
      }
    const regions = getSuggestionRegions(pages, table);
    suggestions[table.id].regions = regions;
  }
  let res = [];
  for (let suggestion in suggestions)
    res.push(suggestions[suggestion])
  return res;
}

const getSuggestionRegions = (pages, table) =>
{
  let regions = getRegions(pages, table);
  for (let i = 0; i < regions.length; i++) {
    const page = $(`#page_${regions[i].pageIdx}`);
    regions[i].page = page;
  }
  return regions;
}

const getPageText = (pages) =>
{
  let text = ""
  for (let i = 0; i < pages.length; i++) {
    let rawText = pages[i].rawText;
    text += "\n ==";
    text += `\n P(${i + 1})`;
    text += rawText;
  }
  return text;
}

const getTableAttr = (banks, tableId) =>
{
  let bank = null;
  let format = null;
  let table = null;
  banks.map(b => b.formats.map(f => f.tables.map(t =>
  {
    if (t.id == tableId) {
      bank = b;
      format = f;
      table = t;
    }
  })));
  return {
    bank: bank,
    format: format,
    table: table
  };
}

const getFormatAttr = (banks, formatId) =>
{
  let bank = null;
  let format = null;
  banks.map(b => b.formats.map(f =>
  {
    if (f.id == formatId) {
      bank = b;
      format = f;
    }
  }));
  return {
    bank: bank,
    format: format
  };
}

const getValidRegions = (regions) =>
{
  const res = [];
  for (let region of regions) {
    if (!region.isChanging && (
      (region.width < 12 || region.height < 0) ||
      (region.width + region.x > 100 || region.height + region.y > 100) ||
      (regionOverlap(region, res))))
      continue;
    if (region.data == null) region.data = {}
    if (region.data.id != null)
      region.data.rescan = region.x != region.data.init.x || region.y != region.data.init.y || region.width != region.data.init.width || region.height != region.data.init.height;
    region.data.regionStyle = {
      border: region.data.id == null ? "2px dashed #50b0dd" :
        region.data.rescan ? "2px dashed #90ddb0" : "3px solid #80DBA0"
    };
    res.push(region)
  }
  return res;
}

const regionOverlap = (region, regions) =>
{
  const rX1 = region.x;
  const rX2 = region.x + region.width;
  const rY1 = region.y;
  const rY2 = region.y + region.height;
  const rA = (rX2 - rX1) * (rY2 - rY1);

  for (let r of regions) {
    const tX1 = r.x;
    const tX2 = r.x + r.width;
    const tY1 = r.y;
    const tY2 = r.y + r.height;

    const mX1 = tX1 > rX1 ? tX1 : rX1;
    const mX2 = tX2 < rX2 ? tX2 : rX2;
    const mY1 = tY1 > rY1 ? tY1 : rY1;
    const mY2 = tY2 < rY2 ? tY2 : rY2;

    if (mX2 - mX1 <= 0 || mY2 - mY1 <= 0)
      continue;

    const mA = (mX2 - mX1) * (mY2 - mY1);
    if (mA > rA * 0.4) return true;
  }
  return false;
}

const getDefFormat = (pages, banks, iniPage = 0) =>
{
  if (iniPage >= pages.length || iniPage > 10)
    return null;

  let firstPage = pages[iniPage];
  while (iniPage < pages.length) {
    if (pages[iniPage].rawText.length > 100) {
      firstPage = pages[iniPage];
      break;
    }
    iniPage++;
  }

  let rawText = firstPage.rawText;

  for (let bank of banks)
    for (let format of bank.formats) {
      let refText = format.refText;
      if (getConditionalText(rawText, refText))
        return {
          defBank: bank.id,
          defFormat: format.id
        };
    }

  return getDefFormat(pages, banks, (iniPage + 1));
}

export { getSuggestions, getTableAttr, getPageText, getFormatAttr, getValidRegions, getDefFormat }