import React from 'react';
import { dateFormat } from 'components/util/Format'
import { Link } from 'react-router-dom'
import { usePagination, useDelete, useSet } from 'seed/gql';
import * as queries from 'seed/gql/queries';

import { Loading } from 'seed/helpers'
import styles from 'styles/css/documents/List.module.css';
import cx from 'classnames';

const DOCUMENTS = `
{
  documentPagination {
    totalPages
    documents {
      source
      createdAt
      accountNumber
      declaration { }
      file {
        name
        url
      }
      pages {
        precision
        regions { 
          table {
            format {
              bank {
                name
                initials
              }
            }
          }
        }
      }
    } 
  }
}
`

function List(props) {

  const { url } = props.match;
  const { filterBank = 0, pageNum = 0 } = props;
  const { declaration_id } = props.match.params;
  let query = `declaration=${declaration_id}`
  if (filterBank > 0) query += ` AND pages.regions.table.format.bank=${filterBank}`
  const pageSize = 20;
  const qDocuments = usePagination(DOCUMENTS, pageNum, filterBank != -1 ? pageSize : 250, query);
  const [cDelete] = useDelete(queries.DELETE_DOCUMENT, {
    onCompleted: () => qDocuments.refetch()
  })

  if (qDocuments.loading) return <Loading />;
  if (qDocuments.error) return `Error! ${qDocuments.message}`;

  const getFileName = name =>
    name.length >= 32 ? name.substring(33, name.length) : name

  const deleteDoc = documentId => {
    if (window.confirm("¿Estás seguro?, Al eliminar el documento se eliminaran todos los registros y cálculos"))
      cDelete({ id: documentId });
  }

  const { documents } = qDocuments.data.documentPagination
  const documentsMapped = documents
    .map(doc => {
      let docT = Object.assign({}, doc)
      let regions = [];
      const sum = doc.pages.reduce((all, p) => all + p.precision, 0);
      const avg = (sum / doc.pages.length) || 0;
      docT.pages.map(
        (page) => page.regions.map(
          (region) => regions.push(region)))
      docT.regions = regions;
      docT.precision = avg;
      docT.precisionPer = parseInt((1 - (1 - avg) * 2.2) * 100);
      return docT
    })
    .map(doc => {
      doc.uid = doc.regions.length > 0 ?
        doc.regions[0].table.format.bank.initials : "SIN REGISTROS"
      return doc;
    })
    .filter(doc => filterBank != -1 || (filterBank == -1 && doc.uid == "SIN REGISTROS"))
    .sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime())

  return (
    <div className={cx(styles.module)}>

      {documentsMapped.length == 0 ?
        <div className={styles.emptyList}><p>Sin documentos</p></div> : null
      }

      <div className={cx(styles.list)}>
        {
          documentsMapped
            .map(d =>
              <div className={styles.item} key={d.id}>
                <div className={styles.info}>
                  <p className={styles.name}>{getFileName(d.file.name)}</p>
                  <p className={styles.date}>
                    {dateFormat(d.createdAt)}
                    <span>&nbsp;-&nbsp;{d.regions.length} Registros</span>
                    {
                      d.precision > 0 ?
                        <span
                          style={{ color: d.precisionPer > 85 ? "#80C0A1" : d.precisionPer > 80 ? "#f0b0a0" : "#f09090" }}
                        >&nbsp;({d.precisionPer}%)</span> : null
                    }

                  </p>
                </div>
                <div className={styles.idContainer}>
                  <p className={styles.id}>{d.uid}
                    {
                      d.uid == "SIN REGISTROS" ?
                        <Link to={`${url}/formats/?status=NO_REGISTRY`} className={cx(styles.info)}>
                          <i className={cx("fas fa-info-circle")}></i>
                        </Link > : null
                    }
                  </p>
                </div>
                <div className={cx(styles.options, styles.toRigth)}>
                  <a href="javascript: void(0)" onClick={() => deleteDoc(d.id)} className={cx(styles.btn, styles.toRigth)}>
                    <span className={styles.text}>Eliminar</span>
                  </a>
                  <Link to={`${url}/${d.id}`} className={cx(styles.registries, styles.btn, styles.btnOutlineGreen, styles.toRigth)}>
                    Escaneo
                  </Link >
                </div >

              </div >
            )
        }

      </div >
    </div >
  );
}

export default List;