import React, { useState } from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import styles from 'styles/css/calcs/CriterionRefipre.module.css';
import { Loading, MultiField } from "seed/helpers";
import { Formik, Field, Form } from "formik";
import { DEV_MODE } from 'settings';

const shareAlienations = [
  { value: "normal", text: "NORMAL" },
  { value: "normal_with", text: "NORMAL + INFLACIÓN" },
]

const ofdAlienations = [
  { value: "normal", text: "NORMAL" },
  { value: "normal_with", text: "NORMAL + INFLACIÓN" },
]

const privateEquities = [
  { value: "usd_inf", text: "USD CON ACTUALIZACIÓN" },
  { value: "usd", text: "USD SIN ACTUALIZACIÓN" }
]


const defaultValue = {
  share_alienation: shareAlienations[0].value,
  ofd_alienation: ofdAlienations[0].value,
  option_factor: "0.0",
  include_dividends: "true",
  private_equity: privateEquities[0].value,
}

if (!DEV_MODE)
  delete defaultValue.private_equity;


function Panel(props) {

  const { declaration_id } = props.match.params;
  const { path, url } = props.match;
  const { showFullFeatures } = props;
  const userId = sessionStorage.getItem("id")

  const [submitType, setSubmitType] = useState("GENERATE")
  const [loading, setLoading] = useState(false);

  const [cMonitor, qMonitor] = usePost("/processes/status", {
    onCompleted: data => {
      if (data.status == "IN_PROCESS") {
        setLoading(true)
        setTimeout(() => cMonitor({ uuid: data.uuid }), 5000)
        return
      }
      setLoading(false)
      if (data.status == "OK") {
        if (data.type == "EXEC_CALC") {
          window.location.href = data.result["url"];
          alert("Cálculo completado");
          setTimeout(() => window.location.replace("/"), 500)
        }
      } else
        alert("Ha ocurrido un error, prueba de nuevo")

    },
    onError: data => alert("Ha ocurrido un error, prueba de nuevo")
  });


  const [cPost, qPost] = usePost("/calcs/exec", {
    onCompleted: data => cMonitor({ uuid: data.uuid }),
    onError: () => alert("Error ejecutando cálculo")
  });

  const [cPostExport, qPostExport] = usePost("/calcs/export_pdf", {
    onCompleted: data => {
      alert("Exportación en proceso: En cuando quede listo el reporte, te enviaremos una notificación por correo electrónico")
      window.location.href = "/"
    },
    onError: () => alert("Error ejecutando cálculo")
  });


  if (loading) return (
    <div className={styles.module}>
      <div className={cx(styles.paperLoading, styles.flexCenter)}>
        <Loading />
      </div>
    </div>);

  const onClickExec = (values) => {
    const userId = sessionStorage.getItem("id")
    values.include_dividends = values.include_dividends == "true"
    if (submitType == "GENERATE") cPost({ declaration_id: declaration_id, is_refipre: true, criteria: values })
    else {
      let confirm = window.confirm("Dedido al tiempo de generación, recomendamos exportar el cálculo en la versión final")
      if (confirm)
        cPostExport({ declaration_id: declaration_id, user_id: userId, is_refipre: true, criteria: values })
      else window.location.href = url
    }
  }


  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4>Selección de criterios</h4>
      </div>

      <div className={cx(styles.paper, styles.flexCol)}>
        <div className={cx(styles.modalDeclaration, styles.flexCol)}>

          <Formik
            initialValues={defaultValue}
            onSubmit={onClickExec}>
            {({ values, setFieldValue }) =>
              <Form>

                <div className={cx(styles.flex, styles.jump)}>
                  <div className={cx(styles.flexFill, styles.marginRight)}>
                    <label for="share_alienation">Enajenación de acciones</label>

                    <Field as="select" name="share_alienation" id="share_alienation"
                      className={styles.select} required>
                      <option value="" selected>Seleccione una opción</option>
                      {shareAlienations.map((item) => <option value={item.value} >{item.text}</option>)}
                    </Field>
                  </div>
                </div>

                <div className={cx(styles.flex, styles.jump)}>
                  <div className={cx(styles.flexFill, styles.marginRight)}>
                    <label for="ofd_alienation">Enajenación de OFDs</label>

                    <Field as="select" name="ofd_alienation" id="ofd_alienation"
                      className={styles.select} required>
                      <option value="" selected>Seleccione una opción</option>
                      {ofdAlienations.map((item) => <option value={item.value} >{item.text}</option>)}
                    </Field>
                  </div>
                </div>

                {DEV_MODE ?
                  <div className={cx(styles.flex, styles.jump)}>
                    <div className={cx(styles.flexFill, styles.marginRight)}>
                      <label for="private_equity">Private equity</label>

                      <Field as="select" name="private_equity" id="private_equity"
                        className={styles.select} required>
                        {privateEquities.map((item) => <option value={item.value} >{item.text}</option>)}
                      </Field>
                    </div>
                  </div> : null}

                <hr style={{ margin: "30px 10px" }} />

                <div className={cx(styles.flex, styles.jump)}>
                  <div className={cx(styles.flexFill, styles.marginRight)}>
                    <label for="include_dividends">Incluir tope 10% dividendos</label>

                    <Field as="select" name="include_dividends"
                      className={styles.select} id="include_dividends" required>
                      <option value="" selected>Seleccione una opción</option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </Field>
                  </div>
                </div>

                <button type="submit" onClick={() => setSubmitType("GENERATE")}
                  className={cx(styles.btn, styles.btnGreen, styles.fullBtn)} style={{ marginTop: "10px" }}>
                  <span className={styles.text}>Generar cálculo</span>
                </button>
                {showFullFeatures ?
                  <button type="submit" onClick={() => setSubmitType("EXPORT")}
                    className={cx(styles.btn, styles.btnExport, styles.fullBtn)} style={{ marginTop: "10px" }}>
                    <span className={styles.text}>Exportar cálculo a PDF</span>
                  </button> : null}

              </Form>
            }</Formik>

        </div>
      </div>
    </div>
  );
}

export default Panel;