import React, { useState } from 'react';
import $ from 'jquery';

import { Document, Page, pdfjs } from 'react-pdf';

import cx from "classnames";
import styles from 'styles/css/scan/table/Preview.module.css';
import RegionSelect from 'react-region-select';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Preview(props)
{
  const { url, pageIdx = 1, defScale } = props;
  const { x, y, width, height } = props;  

  const [ratio, setRatio] = useState(0);


  if (url == null || width == null)
    return <div className={styles.module}></div>

  const scale = defScale ? defScale : 100 / (width + 7);
  const docWidth = 480 * scale;
  const marginLeft = (-(x - 3) * 480 / 100) * scale;
  const marginTop = (-(y - 5) * 480 * ratio / 100) * scale;


  let regions = [];
  const region = {
    data: {
      regionStyle: {
        border: "2px dashed #50b0dd",
        zIndex: "100"
      }
    },
    x: x,
    y: y,
    width: width,
    height: height,
    isChanging: false,
    isNew: true
  };
  regions.push(region);

  const onLoadSuccess = () =>
  {
    setTimeout(() =>
      setRatio($(`.${styles.page}`).height() / $(`.${styles.page}`).width())
      , 500)
  }

  return (
    <div className={styles.module}>
      <div
        className={styles.docWrapper}
        style={{ marginLeft: marginLeft + "px", marginTop: marginTop + "px" }}>
        <Document
          file={url}
          className={styles.document}
          onLoadSuccess={onLoadSuccess}>
          <RegionSelect
            className={styles.region}
            style={{ width: docWidth + "px" }}
            maxRegions={100}
            regions={regions}>

            <Page
              width={docWidth}
              renderMode={"canvas"}
              renderTextLayer={false}
              renderInteractiveForms={false}
              renderAnnotationLayer={false}
              className={cx(styles.page)}
              pageNumber={pageIdx} />
          </RegionSelect>
        </Document>
      </div>
    </div>
  );
}

export default Preview;