import React from 'react';
import { useQuery, usePagination} from 'seed/gql'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/transactions/Table.module.css';
import queryString from 'query-string';

import { getNumFormat, getDateFormat } from 'components/transactions/Table.lutil';

const ENTITIES = `
{
  entities {
    name
    bankAccounts {
      number
      bank {
        name
      }
    }
  }
}
`

const MOVCASHES = `
{
  movcashPagination {
    totalPages
    movcashes {
      idx
      uid
      accountNumber
      description
      originalCurrency
      numberOfTitles
      investment
      date
      amountOriginalCurrency
      symbolCusip
      typeM
      type
      reference {
        row
        region {
          table {
            name
            format {
              bank {
                name
              }
            }
          }
          page {
            document { 
              file {
                url
              }
            }
          }
        }
      }
    }
  }
}
`

function Table(props) {

  const { url } = props.match;
  const { pageNum = 1, filter = "" } = props;
  const { month = "", symbol = "", accountNumber = "", currency = "", includeHistorical = false } = queryString.parse(props.location.search);
  const { declaration_id } = props.match.params;
  const pageSize = 150;
  const qEntities = useQuery(ENTITIES, `declaration=${declaration_id}`)
  const movcashQuery = `declaration=${declaration_id}` +
  (filter != "" ? ` AND accountNumber=${filter}` : "") +
  (accountNumber != "" ? ` AND accountNumber=${accountNumber}` : "") +
  (currency != "" ? ` AND originalCurrency=${currency}` : "") +
  (symbol != "" ? ` AND symbolCusip=${symbol}` : "") +
  ` AND (origin=SCANNED OR origin=CORRECTED OR origin=LOADED ${includeHistorical ? "OR ORIGIN=HISTORIC" : ""}) `

  const qMovcashes = usePagination(MOVCASHES, pageNum, pageSize, movcashQuery, { orderBy: "id" })


  if (qMovcashes.loading) return <div className={styles.loading}><Loading /></div>
  if (qMovcashes.error) return `Errorr! ${qMovcashes.error}`;

  let { movcashes = [] } = qMovcashes.data.movcashPagination;
  const { entities = [] } = qEntities.data;

  let accounts = {};

  for (let movcash of movcashes)
    if (accounts[movcash.accountNumber] == null && movcash.reference != null)
      accounts[movcash.accountNumber] = `${movcash.reference.region.table.format.bank.name}`

  for (let entity of entities)
    for (let account of entity.bankAccounts)
      if (accounts[account.number] != null)
        accounts[account.number] = `${account.bank.name} (${entity.name})`

  let accountOps = [];
  for (let account in accounts)
    accountOps.push(<option value={account}>{"***" + (account.length >= 4 ? account.substring(account.length - 4, account.length) : "")} - {accounts[account]}</option>)

  let res = [];
  let headerData = ["Cuenta", "Título (Descripción)", "Fecha", "Monto", "Moneda", "Symbol/ISIN", "Unidades", "Tipo EC", "Tipo Sistema", "Nombre de archivo"]
  let attrs = ["accountNumber", "description", "date", "amountOriginalCurrency", "originalCurrency", "symbolCusip", "numberOfTitles", "type", "typeM", "fileName"]
  let movFilter = [...movcashes]
    .filter(m => filter == "" || (m.accountNumber == filter))
    .filter(m => {
      if (month == "") return true;
      try {
        let dateStr = m.date;
        let yearI = parseInt(dateStr.split("/")[2]);
        let monthI = parseInt(dateStr.split("/")[1]);
        let keyI = yearI + "" + ("00" + monthI).slice(-2);
        return month == keyI;
      } catch (e) {
        return false;
      }
    })
    .sort((f1, f2) => f1.id - f2.id)
    

  for (let movcash of movFilter) {
    let row = [];
    for (let attr of attrs) {
      let value = movcash[attr];
      if (attr == "accountNumber")
        value = value.length > 21 ? "…" + value.substring(value.length - 1 - 21, value.length - 1) : value
      if (attr == "amountOriginalCurrency")
        value = getNumFormat(value)
      if (attr == "numberOfTitles")
        value = getNumFormat(value, 0)
      if (attr == "date")
        value = getDateFormat(value)
      if (attr == "type")
        value = value.length > 25 ? value.substring(0, 25) + "…" : value
      if (attr == "description")
        value = value.length > 50 ? value.substring(0, 50) + "…" : value
      if (attr == "fileName" && movcash.reference != null) {
        value = attr["id"]
        let url = movcash.reference.region.page.document.file.url;
        let path = decodeURI(url.substring(url.lastIndexOf("/") + 34));
        value = path.length > 30 ? path.substring(0, 30) + "…" : path
      }
      if (movcash.reference != null)
        row.push({
          value: value, attr: attr, id: movcash.id,
          documentId: movcash.reference.region.page.document.id,
          docName: "",
          tableId: movcash.reference.region.table.id,
          tableName: movcash.reference.region.table.name,
          regionId: movcash.reference.region.id,
          row: movcash.reference.row
        });
      else row.push({
        value: value, attr: attr, id: movcash.id,
        documentId: 0, docName: "", tableId: 0, tableName: "", regionId: 0, row: 0
      })
    }
    res.push(row)
  }

  // GROUPING

  let documents = {};
  for (let r of res) {
    if (documents[r[0].docName] == null)
      documents[r[0].docName] = [];
    documents[r[0].docName].push({
      docName: r[0].docName,
      documentId: r[0].documentId,
      data: r
    });
  }

  let grouped = [];
  for (let r in documents)
    grouped.push(documents[r]);


  return (
    <div className={styles.module}>

      <div className={styles.content}>

        <div className={styles.table}>

          <table className={styles.header}>
            <tr>
              {headerData.map((header, idx) => <th key={idx}>{header}</th>)}
            </tr>
          </table>
          {
            grouped.map((doc, idxd) =>
              <div>
                <label style={{ marginTop: idxd == 0 ? "15px" : "25px" }}></label><br />
                {
                  <table>
                    {
                      doc.map(
                        (table, idx) =>
                          <tr key={idx}>
                            {table.data.map((col, idxc) =>
                              <td>{col.value}</td>
                            )}
                          </tr>)
                    }
                  </table>
                }

              </div>
            )
          }

        </div>

      </div>
    </div>
  );
}

export default Table;