import React, { useEffect } from 'react';
import { usePost } from 'seed/api'
function Logout(props)
{
  const [logout] = usePost("/auth/logout", {
    onCompleted: data =>
    {
      sessionStorage.clear();
      localStorage.clear();
      props.history.replace('/');
    }
  });

  useEffect(() => logout(), []);

  return (
    <div></div>
  );
}

export default Logout;
