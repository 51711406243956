import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import $ from 'jquery';
import { useSet, useDelete, useQuery } from 'seed/gql'
import * as queries from 'seed/gql/queries'
import ReactDataSheet from 'react-datasheet';
import { CSVLink } from "react-csv";
import Preview from 'components/scan/table/Preview';
import { Loading } from 'seed/helpers'
import styles from 'styles/css/scan/table/Table.module.css';
import { REGIONS, CELLS } from 'components/scan/table/Table.lqueries';


function Table(props)
{
  const { document_id, table_id, region_id, row_idx } = props.match.params;

  const qRegions = useQuery(REGIONS, `page.document=${document_id} AND table=${table_id}`)
  const qCells = useQuery(CELLS, `region.page.document=${document_id} AND region.table=${table_id}`)
  const [preview, setPreview] = useState(null);

  const [callSet] = useSet(queries.SET_CELL)
  const [callDel] = useDelete(queries.DELETE_CELL, {
    onCompleted: () => qCells.refetch()
  })
  const [callSave] = useDelete(queries.SAVE_CELL, {
    onCompleted: () => qCells.refetch()
  })

  if (qCells.loading || qRegions.loading) return <div className={styles.loading}><Loading /></div>
  if (qCells.error || qRegions.error) return `Errorr! ${qCells.error}`;

  const { regions = [] } = qRegions.data;
  const { cells = [] } = qCells.data;


  const onChange = changes => 
  {
    for (let change of changes) {
      const value = change.value;
      if (value.endsWith("++")) addCell(change.cell);
      else if (value.endsWith("--")) deleteCell(change.cell);
      else setCell(change.cell, value)
    }
  }

  const onChangeTextArea = (e, row, id) =>
  {
    if (e.keyCode !== 13) return;
    let cell = null;
    for (let c of row)
      if (c.id == id) cell = c
    cell.value = e.target.value
    const changes = [{ cell: cell, value: cell.value }]
    onChange(changes)
  }

  const onAreaEnter = (cell) =>
  {
    const regionId = cell.region;
    let url = ""
    let idx = 0;
    for (let region of regions) {
      if (region.id == regionId) {
        url = region.page.document.file.url;
        idx = region.page.refIndex;
      }
    }
    const preview = {
      url: url,
      pageIdx: idx,
      x: cell.x,
      y: cell.y,
      width: cell.width,
      height: cell.height
    }
    setPreview(preview);
  }

  const onAreaLeave = () =>
  {
    setPreview(null)
  }

  const findCol = (index, regionId) =>
    regions.filter(r => r.id == regionId)[0].
      table.columns.filter(c => c.index == index)[0];

  const addCell = cell =>
  {
    const col = findCol(cell.col, cell.region);
    if (col == null) return;
    for (let c of cells)
      if (c.row == cell.row && c.region == cell.region &&
        c.col.index <= cell.col) {
        let newCol = findCol(c.col.index + 1);
        if (newCol != null)
          callSet({ col: newCol.id })
      }
    callSave({
      value: "",
      row: cell.row,
      region: cell.region,
      col: col.id
    });
  }

  const deleteCell = cell =>
  {
    for (let c of cells)
      if (c.row == cell.row && c.region == cell.region &&
        c.col.index > cell.col) {
        let newCol = findCol(c.col.index - 1);
        if (newCol != null)
          callSet({ col: newCol.id })
      }
    callDel({ id: cell.id })
  }

  const setCell = (cell, value) =>
    callSet({
      id: cell.id,
      value: value
    });


  const deleteRow = row =>
  {
    for (let col of row)
      callDel({ id: col.id })
    qRegions.refetch();
    window.setTimeout(() => qRegions.refetch, 2000)

  }

  let cellsSorted = [...cells]
  cellsSorted = cellsSorted.map(c => {
    let cT = Object.assign({}, c)
    cT.uid = (c.row * 100 + parseInt(c.region.id) * 10000)
    return cT
  })
  cellsSorted.sort((a, b) => (a.uid - b.uid));
  const values = [...cellsSorted];

  let res = []

  let headers = [];
  let columnSorted = [...regions[0].table.columns]
  columnSorted.sort((c1, c2) => c1.index - c2.index)
  for (let col of columnSorted)
    headers.push({ readOnly: true, value: col.name });
  res.push(headers);


  let currentUid = 0;
  for (let j = 0; j < values.length; j++) {
    if (currentUid != values[j].uid) {
      let row = [];
      currentUid = values[j].uid;
      for (let i = j; i < values.length && currentUid == values[i].uid; i++) {
        row.push({
          value: values[i].value, id: values[i].id,
          col: values[i].col.index, colName: values[i].col.name,
          row: values[i].row, region: values[i].region.id,
          x: values[i].x, y: values[i].y, width: values[i].width, height: values[i].height,
          component: <textarea onKeyDown={(e) => onChangeTextArea(e, row, values[i].id)}>{values[i].value}</textarea>
        });
        j = i;
      }
      row = row.sort((a, b) => a.col - b.col)
      res.push(row)
    }
  }
  const csv = res.map(row => row.map(col => col.value))

  res = res.map((row, idx) =>
  {
    row.unshift({
      value: "x",
      component: (
        idx > 0 ? <div onClick={() => deleteRow(row)}><i className="fas fa-times-circle"></i></div> : <div></div>
      ),
      disableEvents: true,
      className: styles.delCel,
      forceComponent: true
    })
    return row;
  })

  const valueToHtml = val =>
  {
    let ps = val.split("\n")
    let res = []
    for (let p of ps) {
      res.push(p)
      res.push(<br />)
    }
    res.splice(-1, 1)
    return <span className='value-viewer'>{res}</span>
  }

  const height = $(window).height();
  const fileUrl = regions.length > 0 ?
    regions[0].page.document.file.url : null;

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Vaciado de datos</h4>
      </div>

      <div className={styles.content} style={{ height: (height - 100) + "px" }}>

        <div className={styles.table}>
          <div className={styles.notice}>Da doble click a una celda para modificar su contenido
          </div>
          <ReactDataSheet
            data={res}
            valueRenderer={cell => cell.value}
            valueViewer={props => valueToHtml(props.value)}
            overflow={"clip"}
            cellRenderer={(props) =>
              <td {...props}
                style={{
                  minWidth: props.cell.id != null ? props.cell.value.length < 17 ? "130px" : "380px" : "auto",
                  background: row_idx != null && props.cell.row == row_idx && props.cell.region == region_id ? "#60C5C5" : "none"
                }}
                onMouseEnter={() => onAreaEnter(props.cell)}
                onMouseLeave={() => onAreaLeave()}></td>}
            onCellsChanged={onChange}
          />
        </div>

        {
          preview != null ?
            <Preview
              url={preview.url}
              pageIdx={preview.pageIdx}
              x={preview.x}
              y={preview.y}
              width={preview.width}
              height={preview.height}
            />
            :

            <Preview
              url={regions[0].page.document.file.url}
              pageIdx={regions[0].page.refIndex}
              defScale={regions[0].page.width / regions[0].page.height}
              x={0}
              y={0}
              width={regions[0].page.width}
              height={regions[0].page.height}
            />
        }

      </div>
    </div >
  );
}

export default Table;
