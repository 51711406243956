import React from 'react';
import cx from 'classnames';
import { useDetail, useQuery } from 'seed/gql';
import { usePost, useGet } from 'seed/api';
import { screenSize } from 'components/util/Format';
import { Link, Route } from 'react-router-dom';
import Upload from 'components/transactions/Upload';
import UploadNac from 'components/transactions_nac/Upload';
import Criterion from 'components/calcs/Criterion';
import CriterionRefipre from 'components/calcs/CriterionRefipre';
import { Modal } from 'seed/helpers';
import styles from 'styles/css/calcs/Calcs.module.css';
import { Loading } from 'seed/helpers';
import { IS_PROD, IS_EARLY_ADOPTER } from 'settings';



const TEAMS = `
{
  teams {
    name
  }
}
`

const DECLARATION = `
{
  declaration {
    referenceDate
  }
}
`

function Panel(props) {

  const { declaration_id } = props.match.params;
  const { path, url } = props.match;
  const userId = sessionStorage.getItem("id")

  const qDeclaration = useDetail(DECLARATION, declaration_id);
  const qValidations = useGet(`/calcs/validations`, { declaration_id: declaration_id });
  const qTeams = useQuery(TEAMS, `members=${userId}`);


  if (qValidations.loading || qValidations.data == null || qTeams.data == null) return <Loading />
  if (qDeclaration.loading || qTeams.loading) return <Loading />
  if (qValidations.error) return "Error";

  const teamName = qTeams.data.teams[0].name
  const isEarlyAdopter = IS_EARLY_ADOPTER(teamName)
  const showFullFeatures = !IS_PROD || isEarlyAdopter;
  const validations = qValidations.data
  const isCalcValid = validations.length == 0
  const { declaration = {} } = qDeclaration.data
  const year = (new Date(declaration.referenceDate)).getFullYear();


  const UploadModal = props =>
    <Modal width={450} height={470}{...props} animation={""}>
      <Upload {...props} onlyDownload={false} />
    </Modal>

  const UploadModalNac = props =>
    <Modal width={450} height={470}{...props} animation={""}>
      <UploadNac {...props} onlyDownload={false} />
    </Modal>

  const CriterionModal = props =>
    <Modal width={600} height={600}{...props} animation={""}>
      <Criterion {...props} showFullFeatures={showFullFeatures} />
    </Modal>

  const CriterionRefipreModal = props =>
    <Modal width={600} height={600}{...props} animation={""}>
      <CriterionRefipre {...props} showFullFeatures={showFullFeatures} />
    </Modal>

  return (
    <div className={styles.module}>

      <div className={styles.statusContainer}
        style={{ height: (screenSize() - 190) + "px" }}>

        <div className={styles.status}>

          <div className={styles.message}>
            <i className={cx("fas", isCalcValid ? "fa-play" : "fa-exclamation-triangle", styles.icon)} onClick={null}></i><br />
            {isCalcValid ? "Procesar Cálculo" : "Revisar movimientos"}<br />

            <div className={styles.options}>
              {isCalcValid ?
                <div className={styles.executeCalcContainer}>
                  <Link to={`${url}/criterion`}>
                    <label className={cx(styles.exportCalc)}>
                      <button className={cx(styles.btn, styles.btnGreen)}>
                        <i className="fas fa-plus-circle"></i>&nbsp;
                        Generar cálculo <small>(Persona física)</small></button>
                    </label>
                  </Link><br /><br />
                  <Link to={`${url}/criterion_refipre`}>
                    <label className={cx(styles.exportCalc)}>
                      <button className={cx(styles.btn, styles.btnGreen)}>
                        <i className="fas fa-plus-circle"></i>&nbsp;
                        Generar cálculo <small>(REFIPRE)</small></button>
                    </label>
                    <br />
                  </Link>
                  <br />

                  <div className={styles.messageContainer}>
                    <div className={styles.messageContent}>
                      <div>
                        Importante: El ejercicio se ejecutará del <br /> <b>1 de enero de {year} al 31 de diciembre de {year}</b>
                      </div>
                      <hr />
                      <small>
                        Para modificar este año, seleccionar el icono  <i className="fas fa-pencil-alt"></i> del menú izquierdo de declaraciones
                      </small>
                    </div>
                  </div>
                </div>
                :
                <div className={styles.warningContainer}>
                  <div className={styles.warningContent}>
                    {
                      validations.map(v =>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          <b>Celda {v.pos}: </b> {v.message}
                        </div>)
                    }
                  </div>
                  <hr />
                  <span className={styles.actionTitle}>ACCIONES</span>
                  <br />
                  <Link to={`${url}/validate`} className={cx(styles.btn, styles.btnGreen)}>
                    <i className="fas fa-globe mr-2"></i>
                    Modificar movimientos de extranjero
                  </Link>
                  <br/><br/>

                  <Link to={`${url}/validate_nac`} className={cx(styles.btn, styles.btnGreen)}>
                    <i className="fas fa-flag mr-2"></i>
                    Modificar ingresos nacionales
                  </Link>

                </div>
              }
            </div>

          </div>

        </div>

      </div>

      <Route path={`${path}/validate`}
        component={UploadModal} />

      <Route path={`${path}/validate_nac`}
        component={UploadModalNac} />

      <Route path={`${path}/criterion`}
        component={CriterionModal} />

      <Route path={`${path}/criterion_refipre`}
        component={CriterionRefipreModal} />


    </div>
  );
}

export default Panel;
