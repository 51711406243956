import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { Loading } from 'seed/helpers';
import { usePost } from 'seed/api';
import styles from 'styles/css/transactions_nac/Upload.module.css';
import * as XLSX from 'xlsx';
import * as JSON2CSV from 'json2csv';


function Upload(props) {
  const { url } = props.match;
  const { declaration_id } = props.match.params;
  const { onlyDownload = true, accountNumber = ""} = props
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fileRef = useRef(null);

  const [cMonitor, qMonitor] = usePost("/processes/status", {
    onCompleted: data => {
      if (data.status == "IN_PROCESS"){
        setLoading(true)
        setTimeout(() => cMonitor({uuid: data.uuid}), 5000)
        return
      }
      setLoading(false)
      if (data.status == "OK"){
        if (data.type == "LOAD_NAC"){
          alert("Tus archivos se han cargado correctamente");
          const backUrl = url.substring(0, url.lastIndexOf("/"));
          window.location.href = backUrl;
        }
        if (data.type == "EXPORT_NAC")
          window.location.href = data.result["url"];      
      } else
        alert("Ha ocurrido un error, prueba de nuevo")
      
    },
    onError: data => alert("Ha ocurrido un error, prueba de nuevo")
  });


  const [cExport, qExport] = usePost("/movcash_nacs/export_transactions", {
    onCompleted: data => cMonitor({uuid: data.uuid}),
    onError: data => alert("Ha ocurrido un error al descargar tus datos")
  });

  const [cSync, qSync] = usePost("/movcash_nacs/load_transactions", {
    onCompleted: data => cMonitor({uuid: data.uuid}),
    onError: data => alert("Ha ocurrido un error al cargar tus datos")
  })

  if (qExport.loading || qSync.loading || loading) return (
    <div className={styles.loading}>
      <Loading />
      <div className={styles.status}>Cargando...</div>
    </div>);

  const onClickDownload = () => {
    cExport({ declaration_id: declaration_id, account_number: accountNumber })
  }

  const handleInputFileChange = e => {
    e.preventDefault();
    let file = fileRef.current.files[0];
    let re = /(\.xlsx)$/i;
    if (file && re.exec(file.name)) {
      let reader = new FileReader();
      reader.onload = event => {
        const bstr = event.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const fieldNames = ["Concepto", "Fecha", "Monto", "Moneda", "Emisora", "RFC", "Identificador / # Constancia", "Tipo Sistema", "Notas", "ID Sistema"]
        const json = XLSX.utils.sheet_to_json(ws);
        let keys = []
        for (let item of json)
          for (let key in item)
            keys.push(key)
        keys = keys.filter((v, i, a) => a.indexOf(v) === i);
        let fields = []
        for (let fieldName of fieldNames) {
          let done = false
          for (let key of keys)
            if (key.includes(fieldName) && !done) {
              fields.push(key)
              done = true
            }
          if (!done) fields.push(fieldName)
        }
        const csv = JSON2CSV.parse(json, { fields })
        setCsvData(csv);
      }
      reader.readAsBinaryString(file);
    } else alert("Failed to load data")
  }

  const onClickSend = () => {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
      cSync({ csv_raw: csvData, declaration_id: declaration_id });
    }, 0)
  }


  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4>{onlyDownload ? "Descargar ingresos nacionales" : "Modificar ingresos nacionales"}</h4>
      </div>

      {
        onlyDownload ? 
        <div className={(styles.paper, styles.form)}>
          <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)} onClick={onClickDownload}>
            <span className={styles.text}>Descargar</span>
          </button>
        </div>
        :
        <div className={(styles.paper, styles.form)}>
        <div className={styles.notice}>
          <span>INSTRUCCIONES</span>
          <ol>
            <li>Descarga los últimos movimientos registrados<br />
              <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)} onClick={onClickDownload}>
                <span className={styles.text}>Descargar</span>
              </button>
            </li><br />
            <li>Modifica o carga nuevos movimientos</li>
            <li>Vuelve a subir el archivo de excel</li>
          </ol>
        </div>

        <span>Subir datos</span>
        <input name="key"
          type="file"
          ref={fileRef}
          placeholder="Selecciona el excel"
          accept=".xlsx"
          className={cx(styles.fil, styles.fileFil)}
          onChange={handleInputFileChange} />
        <br /><br />

        {csvData ?
          <button type="button"
            onClick={onClickSend}
            className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            Cargar datos</button> : null
        }

      </div>
      }
      
    </div>

  );
}

export default Upload;
