import React from 'react';
import queryString from 'query-string';

function LoginAuthND(props)
{
  const { status, token } = queryString.parse(props.location.search) 
  if (status == "OK"){
    sessionStorage.setItem('tokenND', token);
    window.location.replace("/app/scan");
  }else return <div>Ha ocurrido un error iniciando sesión</div>;
}

export default LoginAuthND;
