import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDetail } from 'seed/gql';
import { useGetCall } from 'seed/api';
import { Switch, Route, Redirect } from 'react-router-dom';
import { screenSize } from 'components/util/Format'
import styles from 'styles/css/Home.module.css';
import Topnav from 'components/navigation/Topnav';
import Content from 'components/navigation/Content';
import Declarations from 'components/declarations/Declarations';
import { Loading } from 'seed/helpers';

const USER = `
{
  user {
    id
    firstName
    lastName
    rol
  }
}
`

function Home(props)
{
  const { path, url } = props.match;
  const [role, setRole] = useState("");
  const declarationId = localStorage.getItem("declarationId")
  const DefRedirect = props =>
    declarationId ?
      <Redirect to={`${url}/${declarationId}`} /> : null;
  
  const [isAuth, setIsAuth] = useState(false);
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      setIsAuth(true);
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/login");
    }
  })  
    
  useEffect(() =>
  {
    const userId = sessionStorage.getItem('id');
    if (userId == null)
      return props.history.replace(`/login`);
    callAuth();
  }, []);

  const userId = sessionStorage.getItem("id")
  useDetail(USER, sessionStorage.getItem("id"), {
    onCompleted: data =>
    {
      const role = data.user.rol;
      sessionStorage.setItem("role", role)
      setRole(role)
    }
  });

  if (!isAuth) return null;

  if (userId != null && role == "")
    return <Loading />;    

  return (
    <div className={styles.module}>
      <Route path={`${path}`} component={Topnav} />
      
      <div className={styles.home}>
        <div className={styles.flex}>

          <div className={cx(styles.col3, styles.card, styles.declarations)} style={{height: (screenSize()-90) + "px" }}>
            <Route path={`${path}`} component={Declarations} />
          </div>

          
          <div className={cx(styles.col9, styles.card, styles.contentContainer)} style={{height: (screenSize()-90) + "px" }}>
            <Switch>
              <Route path={`${path}/:declaration_id(\\d+)`} component={Content} style={{height: (screenSize()-90) + "px" }}/>
              <Route path={`${path}/new`} component={() => <div></div>} />   
              <DefRedirect />
            </Switch>            
          </div>
          
        </div>
      </div>

    </div>
  );
}

export default Home;
