/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const BANK = `
{
  bank {
    id
    name
    initials
  }
}
`;

export const SET_BANK = `
mutation Set(
  $id: Int!,
  $name: String,
  $initials: String,
)
{
  setBank(
    id: $id,
    name: $name,
    initials: $initials,
  ) {
    bank {
      id
      name
      initials
    }
  }
}
`;

export const SAVE_BANK = `
mutation Save(
  $name: String!,
  $initials: String!,
)
{
  saveBank(
    name: $name,
    initials: $initials,
  ) {
    bank {
      id
    }
  }
}
`;

export const DELETE_BANK = `
mutation Delete($id: Int!)
{
  deleteBank(id: $id)
  {
    id
  }
}
`;

export const BANK_ACCOUNT = `
{
  bankAccount {
    id
    number
    holding
    bank {
      id
    }
    entity {
      id
    }
  }
}
`;

export const SET_BANK_ACCOUNT = `
mutation Set(
  $id: Int!,
  $number: String,
  $holding: Float,
  $bank: Int,
  $entity: Int,
)
{
  setBankAccount(
    id: $id,
    number: $number,
    holding: $holding,
    bank: $bank,
    entity: $entity,
  ) {
    bankAccount {
      id
      number
      holding
      bank {
        id
      }
      entity {
        id
      }
    }
  }
}
`;

export const SAVE_BANK_ACCOUNT = `
mutation Save(
  $number: String!,
  $holding: Float,
  $bank: Int!,
  $entity: Int!,
)
{
  saveBankAccount(
    number: $number,
    holding: $holding,
    bank: $bank,
    entity: $entity,
  ) {
    bankAccount {
      id
    }
  }
}
`;

export const DELETE_BANK_ACCOUNT = `
mutation Delete($id: Int!)
{
  deleteBankAccount(id: $id)
  {
    id
  }
}
`;

export const CALC = `
{
  calc {
    id
    name
    excel {
      id
      url
    }
    declaration {
      id
    }
    movcashes {
      id
    }
  }
}
`;

export const SET_CALC = `
mutation Set(
  $id: Int!,
  $name: String,
  $excel: Int,
  $declaration: Int,
  $movcashes: [Int],
)
{
  setCalc(
    id: $id,
    name: $name,
    excel: $excel,
    declaration: $declaration,
    movcashes: $movcashes,
  ) {
    calc {
      id
      name
      excel {
        id
      }
      declaration {
        id
      }
      movcashes {
        id
      }
    }
  }
}
`;

export const SAVE_CALC = `
mutation Save(
  $name: String!,
  $excel: Int,
  $declaration: Int!,
  $movcashes: [Int],
)
{
  saveCalc(
    name: $name,
    excel: $excel,
    declaration: $declaration,
    movcashes: $movcashes,
  ) {
    calc {
      id
    }
  }
}
`;

export const DELETE_CALC = `
mutation Delete($id: Int!)
{
  deleteCalc(id: $id)
  {
    id
  }
}
`;

export const CALC_KPI = `
{
  calcKpi {
    id
    bondsHxr
    bondsSxr
    sharesHxr
    gainCumulativeShxr
    lossCumulativeShxr
    gainNoncumulativeShxr
    lossNoncumulativeShxr
    sharesSxr
    gainCumulativeSsxr
    lossCumulativeSsxr
    gainNoncumulativeSsxr
    lossNoncumulativeSsxr
    sharesSxru
    gainCumulativeSsxru
    lossCumulativeSsxru
    gainNoncumulativeSsxru
    lossNoncumulativeSsxru
    annualAdjustIx
    annualAdjustVi
    gainLossYse
    gainLossYee
    gainLossMse
    gainLossMss
    gainLossDp
    gainLossDn
    xchangeEffectFcc
    xchangeEffectBsxrc
    xchangeEffectBhxrc
    dividends
    interests
    transfers
    optionRlisr
    isrDividends10
    isrRetention
    netDividends
    updateDividends
    surchargesDividends
    calc {
      id
    }
  }
}
`;

export const SET_CALC_KPI = `
mutation Set(
  $id: Int!,
  $bondsHxr: Float,
  $bondsSxr: Float,
  $sharesHxr: Float,
  $gainCumulativeShxr: Float,
  $lossCumulativeShxr: Float,
  $gainNoncumulativeShxr: Float,
  $lossNoncumulativeShxr: Float,
  $sharesSxr: Float,
  $gainCumulativeSsxr: Float,
  $lossCumulativeSsxr: Float,
  $gainNoncumulativeSsxr: Float,
  $lossNoncumulativeSsxr: Float,
  $sharesSxru: Float,
  $gainCumulativeSsxru: Float,
  $lossCumulativeSsxru: Float,
  $gainNoncumulativeSsxru: Float,
  $lossNoncumulativeSsxru: Float,
  $annualAdjustIx: Float,
  $annualAdjustVi: Float,
  $gainLossYse: Float,
  $gainLossYee: Float,
  $gainLossMse: Float,
  $gainLossMss: Float,
  $gainLossDp: Float,
  $gainLossDn: Float,
  $xchangeEffectFcc: Float,
  $xchangeEffectBsxrc: Float,
  $xchangeEffectBhxrc: Float,
  $dividends: Float,
  $interests: Float,
  $transfers: Float,
  $optionRlisr: Float,
  $isrDividends10: Float,
  $isrRetention: Float,
  $netDividends: Float,
  $updateDividends: Float,
  $surchargesDividends: Float,
  $calc: Int,
)
{
  setCalcKpi(
    id: $id,
    bondsHxr: $bondsHxr,
    bondsSxr: $bondsSxr,
    sharesHxr: $sharesHxr,
    gainCumulativeShxr: $gainCumulativeShxr,
    lossCumulativeShxr: $lossCumulativeShxr,
    gainNoncumulativeShxr: $gainNoncumulativeShxr,
    lossNoncumulativeShxr: $lossNoncumulativeShxr,
    sharesSxr: $sharesSxr,
    gainCumulativeSsxr: $gainCumulativeSsxr,
    lossCumulativeSsxr: $lossCumulativeSsxr,
    gainNoncumulativeSsxr: $gainNoncumulativeSsxr,
    lossNoncumulativeSsxr: $lossNoncumulativeSsxr,
    sharesSxru: $sharesSxru,
    gainCumulativeSsxru: $gainCumulativeSsxru,
    lossCumulativeSsxru: $lossCumulativeSsxru,
    gainNoncumulativeSsxru: $gainNoncumulativeSsxru,
    lossNoncumulativeSsxru: $lossNoncumulativeSsxru,
    annualAdjustIx: $annualAdjustIx,
    annualAdjustVi: $annualAdjustVi,
    gainLossYse: $gainLossYse,
    gainLossYee: $gainLossYee,
    gainLossMse: $gainLossMse,
    gainLossMss: $gainLossMss,
    gainLossDp: $gainLossDp,
    gainLossDn: $gainLossDn,
    xchangeEffectFcc: $xchangeEffectFcc,
    xchangeEffectBsxrc: $xchangeEffectBsxrc,
    xchangeEffectBhxrc: $xchangeEffectBhxrc,
    dividends: $dividends,
    interests: $interests,
    transfers: $transfers,
    optionRlisr: $optionRlisr,
    isrDividends10: $isrDividends10,
    isrRetention: $isrRetention,
    netDividends: $netDividends,
    updateDividends: $updateDividends,
    surchargesDividends: $surchargesDividends,
    calc: $calc,
  ) {
    calcKpi {
      id
      bondsHxr
      bondsSxr
      sharesHxr
      gainCumulativeShxr
      lossCumulativeShxr
      gainNoncumulativeShxr
      lossNoncumulativeShxr
      sharesSxr
      gainCumulativeSsxr
      lossCumulativeSsxr
      gainNoncumulativeSsxr
      lossNoncumulativeSsxr
      sharesSxru
      gainCumulativeSsxru
      lossCumulativeSsxru
      gainNoncumulativeSsxru
      lossNoncumulativeSsxru
      annualAdjustIx
      annualAdjustVi
      gainLossYse
      gainLossYee
      gainLossMse
      gainLossMss
      gainLossDp
      gainLossDn
      xchangeEffectFcc
      xchangeEffectBsxrc
      xchangeEffectBhxrc
      dividends
      interests
      transfers
      optionRlisr
      isrDividends10
      isrRetention
      netDividends
      updateDividends
      surchargesDividends
      calc {
        id
      }
    }
  }
}
`;

export const SAVE_CALC_KPI = `
mutation Save(
  $bondsHxr: Float,
  $bondsSxr: Float,
  $sharesHxr: Float,
  $gainCumulativeShxr: Float,
  $lossCumulativeShxr: Float,
  $gainNoncumulativeShxr: Float,
  $lossNoncumulativeShxr: Float,
  $sharesSxr: Float,
  $gainCumulativeSsxr: Float,
  $lossCumulativeSsxr: Float,
  $gainNoncumulativeSsxr: Float,
  $lossNoncumulativeSsxr: Float,
  $sharesSxru: Float,
  $gainCumulativeSsxru: Float,
  $lossCumulativeSsxru: Float,
  $gainNoncumulativeSsxru: Float,
  $lossNoncumulativeSsxru: Float,
  $annualAdjustIx: Float,
  $annualAdjustVi: Float,
  $gainLossYse: Float,
  $gainLossYee: Float,
  $gainLossMse: Float,
  $gainLossMss: Float,
  $gainLossDp: Float,
  $gainLossDn: Float,
  $xchangeEffectFcc: Float,
  $xchangeEffectBsxrc: Float,
  $xchangeEffectBhxrc: Float,
  $dividends: Float,
  $interests: Float,
  $transfers: Float,
  $optionRlisr: Float,
  $isrDividends10: Float,
  $isrRetention: Float,
  $netDividends: Float,
  $updateDividends: Float,
  $surchargesDividends: Float,
  $calc: Int,
)
{
  saveCalcKpi(
    bondsHxr: $bondsHxr,
    bondsSxr: $bondsSxr,
    sharesHxr: $sharesHxr,
    gainCumulativeShxr: $gainCumulativeShxr,
    lossCumulativeShxr: $lossCumulativeShxr,
    gainNoncumulativeShxr: $gainNoncumulativeShxr,
    lossNoncumulativeShxr: $lossNoncumulativeShxr,
    sharesSxr: $sharesSxr,
    gainCumulativeSsxr: $gainCumulativeSsxr,
    lossCumulativeSsxr: $lossCumulativeSsxr,
    gainNoncumulativeSsxr: $gainNoncumulativeSsxr,
    lossNoncumulativeSsxr: $lossNoncumulativeSsxr,
    sharesSxru: $sharesSxru,
    gainCumulativeSsxru: $gainCumulativeSsxru,
    lossCumulativeSsxru: $lossCumulativeSsxru,
    gainNoncumulativeSsxru: $gainNoncumulativeSsxru,
    lossNoncumulativeSsxru: $lossNoncumulativeSsxru,
    annualAdjustIx: $annualAdjustIx,
    annualAdjustVi: $annualAdjustVi,
    gainLossYse: $gainLossYse,
    gainLossYee: $gainLossYee,
    gainLossMse: $gainLossMse,
    gainLossMss: $gainLossMss,
    gainLossDp: $gainLossDp,
    gainLossDn: $gainLossDn,
    xchangeEffectFcc: $xchangeEffectFcc,
    xchangeEffectBsxrc: $xchangeEffectBsxrc,
    xchangeEffectBhxrc: $xchangeEffectBhxrc,
    dividends: $dividends,
    interests: $interests,
    transfers: $transfers,
    optionRlisr: $optionRlisr,
    isrDividends10: $isrDividends10,
    isrRetention: $isrRetention,
    netDividends: $netDividends,
    updateDividends: $updateDividends,
    surchargesDividends: $surchargesDividends,
    calc: $calc,
  ) {
    calcKpi {
      id
    }
  }
}
`;

export const DELETE_CALC_KPI = `
mutation Delete($id: Int!)
{
  deleteCalcKpi(id: $id)
  {
    id
  }
}
`;

export const CALC_PROCESS = `
{
  calcProcess {
    id
    status
    finishDate
    processId
    calc {
      id
    }
  }
}
`;

export const SET_CALC_PROCESS = `
mutation Set(
  $id: Int!,
  $status: String,
  $finishDate: DateTime,
  $processId: String,
  $calc: Int,
)
{
  setCalcProcess(
    id: $id,
    status: $status,
    finishDate: $finishDate,
    processId: $processId,
    calc: $calc,
  ) {
    calcProcess {
      id
      status
      finishDate
      processId
      calc {
        id
      }
    }
  }
}
`;

export const SAVE_CALC_PROCESS = `
mutation Save(
  $status: String!,
  $finishDate: DateTime!,
  $processId: String!,
  $calc: Int,
)
{
  saveCalcProcess(
    status: $status,
    finishDate: $finishDate,
    processId: $processId,
    calc: $calc,
  ) {
    calcProcess {
      id
    }
  }
}
`;

export const DELETE_CALC_PROCESS = `
mutation Delete($id: Int!)
{
  deleteCalcProcess(id: $id)
  {
    id
  }
}
`;

export const CELL = `
{
  cell {
    id
    row
    value
    x
    y
    width
    height
    col {
      id
    }
    region {
      id
    }
  }
}
`;

export const SET_CELL = `
mutation Set(
  $id: Int!,
  $row: Int,
  $value: String,
  $x: Float,
  $y: Float,
  $width: Float,
  $height: Float,
  $col: Int,
  $region: Int,
)
{
  setCell(
    id: $id,
    row: $row,
    value: $value,
    x: $x,
    y: $y,
    width: $width,
    height: $height,
    col: $col,
    region: $region,
  ) {
    cell {
      id
      row
      value
      x
      y
      width
      height
      col {
        id
      }
      region {
        id
      }
    }
  }
}
`;

export const SAVE_CELL = `
mutation Save(
  $row: Int!,
  $value: String!,
  $x: Float,
  $y: Float,
  $width: Float,
  $height: Float,
  $col: Int!,
  $region: Int!,
)
{
  saveCell(
    row: $row,
    value: $value,
    x: $x,
    y: $y,
    width: $width,
    height: $height,
    col: $col,
    region: $region,
  ) {
    cell {
      id
    }
  }
}
`;

export const DELETE_CELL = `
mutation Delete($id: Int!)
{
  deleteCell(id: $id)
  {
    id
  }
}
`;

export const CLIENT = `
{
  client {
    id
    user {
      id
    }
    assigned {
      id
    }
  }
}
`;

export const SET_CLIENT = `
mutation Set(
  $id: Int!,
  $user: Int,
  $assigned: [Int],
)
{
  setClient(
    id: $id,
    user: $user,
    assigned: $assigned,
  ) {
    client {
      id
      user {
        id
      }
      assigned {
        id
      }
    }
  }
}
`;

export const SAVE_CLIENT = `
mutation Save(
  $user: Int!,
  $assigned: [Int],
)
{
  saveClient(
    user: $user,
    assigned: $assigned,
  ) {
    client {
      id
    }
  }
}
`;

export const DELETE_CLIENT = `
mutation Delete($id: Int!)
{
  deleteClient(id: $id)
  {
    id
  }
}
`;

export const COLUMN = `
{
  column {
    id
    index
    name
    regex
    weight
    table {
      id
    }
  }
}
`;

export const SET_COLUMN = `
mutation Set(
  $id: Int!,
  $index: Int,
  $name: String,
  $regex: String,
  $weight: Int,
  $table: Int,
)
{
  setColumn(
    id: $id,
    index: $index,
    name: $name,
    regex: $regex,
    weight: $weight,
    table: $table,
  ) {
    column {
      id
      index
      name
      regex
      weight
      table {
        id
      }
    }
  }
}
`;

export const SAVE_COLUMN = `
mutation Save(
  $index: Int!,
  $name: String!,
  $regex: String!,
  $weight: Int!,
  $table: Int!,
)
{
  saveColumn(
    index: $index,
    name: $name,
    regex: $regex,
    weight: $weight,
    table: $table,
  ) {
    column {
      id
    }
  }
}
`;

export const DELETE_COLUMN = `
mutation Delete($id: Int!)
{
  deleteColumn(id: $id)
  {
    id
  }
}
`;

export const CURRENCY = `
{
  currency {
    id
    country
    name
  }
}
`;

export const SET_CURRENCY = `
mutation Set(
  $id: Int!,
  $country: String,
  $name: String,
)
{
  setCurrency(
    id: $id,
    country: $country,
    name: $name,
  ) {
    currency {
      id
      country
      name
    }
  }
}
`;

export const SAVE_CURRENCY = `
mutation Save(
  $country: String!,
  $name: String!,
)
{
  saveCurrency(
    country: $country,
    name: $name,
  ) {
    currency {
      id
    }
  }
}
`;

export const DELETE_CURRENCY = `
mutation Delete($id: Int!)
{
  deleteCurrency(id: $id)
  {
    id
  }
}
`;

export const DECLARATION = `
{
  declaration {
    id
    internalToken
    referenceDate
    description
    clientName
    client {
      id
    }
    members {
      id
    }
    team {
      id
    }
  }
}
`;

export const SET_DECLARATION = `
mutation Set(
  $id: Int!,
  $internalToken: String,
  $referenceDate: DateTime,
  $description: String,
  $clientName: String,
  $client: Int,
  $members: [Int],
  $team: Int,
)
{
  setDeclaration(
    id: $id,
    internalToken: $internalToken,
    referenceDate: $referenceDate,
    description: $description,
    client: $client,
    members: $members,
    clientName: $clientName,
    team: $team,
  ) {
    declaration {
      id
      internalToken
      referenceDate
      description
      clientName
      client {
        id
      }
      members {
        id
      }
      team {
        id
      }
    }
  }
}
`;

export const SAVE_DECLARATION = `
mutation Save(
  $internalToken: String!,
  $referenceDate: DateTime!,
  $description: String,
  $clientName: String!,
  $client: Int,
  $members: [Int],
  $team: Int,
)
{
  saveDeclaration(
    internalToken: $internalToken,
    referenceDate: $referenceDate,
    description: $description,
    client: $client,
    members: $members,
    clientName: $clientName,
    team: $team,
  ) {
    declaration {
      id
    }
  }
}
`;

export const DELETE_DECLARATION = `
mutation Delete($id: Int!)
{
  deleteDeclaration(id: $id)
  {
    id
  }
}
`;

export const DOCUMENT = `
{
  document {
    id
    source
    type
    accountNumber
    iniDate
    endDate
    file {
      id
      url
    }
    defFormat {
      id
    }
    declaration {
      id
    }
  }
}
`;

export const SET_DOCUMENT = `
mutation Set(
  $id: Int!,
  $file: Int,
  $source: String,
  $type: String,
  $accountNumber: String,
  $iniDate: DateTime,
  $endDate: DateTime,
  $defFormat: Int,
  $declaration: Int,
)
{
  setDocument(
    id: $id,
    file: $file,
    source: $source,
    type: $type,
    accountNumber: $accountNumber,
    defFormat: $defFormat,
    declaration: $declaration,
    iniDate: $iniDate,
    endDate: $endDate,
  ) {
    document {
      id
      source
      type
      accountNumber
      iniDate
      endDate
      file {
        id
      }
      defFormat {
        id
      }
      declaration {
        id
      }
    }
  }
}
`;

export const SAVE_DOCUMENT = `
mutation Save(
  $file: Int!,
  $source: String!,
  $type: String!,
  $accountNumber: String!,
  $iniDate: DateTime,
  $endDate: DateTime,
  $defFormat: Int,
  $declaration: Int!,
)
{
  saveDocument(
    file: $file,
    source: $source,
    type: $type,
    accountNumber: $accountNumber,
    defFormat: $defFormat,
    declaration: $declaration,
    iniDate: $iniDate,
    endDate: $endDate,
  ) {
    document {
      id
    }
  }
}
`;

export const DELETE_DOCUMENT = `
mutation Delete($id: Int!)
{
  deleteDocument(id: $id)
  {
    id
  }
}
`;

export const DOLLAR_PARITY = `
{
  dollarParity {
    id
    value
    day
    month
    year
  }
}
`;

export const SET_DOLLAR_PARITY = `
mutation Set(
  $id: Int!,
  $value: Float,
  $day: Int,
  $month: Int,
  $year: Int,
)
{
  setDollarParity(
    id: $id,
    value: $value,
    day: $day,
    month: $month,
    year: $year,
  ) {
    dollarParity {
      id
      value
      day
      month
      year
    }
  }
}
`;

export const SAVE_DOLLAR_PARITY = `
mutation Save(
  $value: Float!,
  $day: Int!,
  $month: Int!,
  $year: Int!,
)
{
  saveDollarParity(
    value: $value,
    day: $day,
    month: $month,
    year: $year,
  ) {
    dollarParity {
      id
    }
  }
}
`;

export const DELETE_DOLLAR_PARITY = `
mutation Delete($id: Int!)
{
  deleteDollarParity(id: $id)
  {
    id
  }
}
`;

export const ENTITY = `
{
  entity {
    id
    type
    name
    country
    companyType
    fiscalConcept
    holding
    isInformative
    informativeName
    informativeAddress
    parent {
      id
    }
    declaration {
      id
    }
  }
}
`;

export const SET_ENTITY = `
mutation Set(
  $id: Int!,
  $type: String,
  $name: String,
  $country: String,
  $companyType: String,
  $fiscalConcept: String,
  $holding: Float,
  $isInformative: Boolean,
  $informativeName: String,
  $informativeAddress: String,
  $parent: Int,
  $declaration: Int,
)
{
  setEntity(
    id: $id,
    type: $type,
    name: $name,
    country: $country,
    companyType: $companyType,
    fiscalConcept: $fiscalConcept,
    holding: $holding,
    isInformative: $isInformative,
    informativeName: $informativeName,
    informativeAddress: $informativeAddress,
    parent: $parent,
    declaration: $declaration,
  ) {
    entity {
      id
      type
      name
      country
      companyType
      fiscalConcept
      holding
      isInformative
      informativeName
      informativeAddress
      parent {
        id
      }
      declaration {
        id
      }
    }
  }
}
`;

export const SAVE_ENTITY = `
mutation Save(
  $type: String!,
  $name: String!,
  $country: String,
  $companyType: String,
  $fiscalConcept: String,
  $holding: Float,
  $isInformative: Boolean,
  $informativeName: String,
  $informativeAddress: String,
  $parent: Int,
  $declaration: Int!,
)
{
  saveEntity(
    type: $type,
    name: $name,
    country: $country,
    companyType: $companyType,
    fiscalConcept: $fiscalConcept,
    holding: $holding,
    isInformative: $isInformative,
    informativeName: $informativeName,
    informativeAddress: $informativeAddress,
    parent: $parent,
    declaration: $declaration,
  ) {
    entity {
      id
    }
  }
}
`;

export const DELETE_ENTITY = `
mutation Delete($id: Int!)
{
  deleteEntity(id: $id)
  {
    id
  }
}
`;

export const EQUIVALENCE = `
{
  equivalence {
    id
    value
    month
    year
    currency {
      id
    }
  }
}
`;

export const SET_EQUIVALENCE = `
mutation Set(
  $id: Int!,
  $value: Float,
  $month: Int,
  $year: Int,
  $currency: Int,
)
{
  setEquivalence(
    id: $id,
    value: $value,
    month: $month,
    year: $year,
    currency: $currency,
  ) {
    equivalence {
      id
      value
      month
      year
      currency {
        id
      }
    }
  }
}
`;

export const SAVE_EQUIVALENCE = `
mutation Save(
  $value: Float!,
  $month: Int!,
  $year: Int!,
  $currency: Int!,
)
{
  saveEquivalence(
    value: $value,
    month: $month,
    year: $year,
    currency: $currency,
  ) {
    equivalence {
      id
    }
  }
}
`;

export const DELETE_EQUIVALENCE = `
mutation Delete($id: Int!)
{
  deleteEquivalence(id: $id)
  {
    id
  }
}
`;

export const FORMAT = `
{
  format {
    id
    name
    year
    month
    refText
    previewImage {
      id
      url
    }
    bank {
      id
    }
  }
}
`;

export const SET_FORMAT = `
mutation Set(
  $id: Int!,
  $name: String,
  $year: Int,
  $month: Int,
  $refText: String,
  $previewImage: Int,
  $bank: Int,
)
{
  setFormat(
    id: $id,
    name: $name,
    year: $year,
    month: $month,
    refText: $refText,
    previewImage: $previewImage,
    bank: $bank,
  ) {
    format {
      id
      name
      year
      month
      refText
      previewImage {
        id
      }
      bank {
        id
      }
    }
  }
}
`;

export const SAVE_FORMAT = `
mutation Save(
  $name: String!,
  $year: Int,
  $month: Int,
  $refText: String!,
  $previewImage: Int,
  $bank: Int!,
)
{
  saveFormat(
    name: $name,
    year: $year,
    month: $month,
    refText: $refText,
    previewImage: $previewImage,
    bank: $bank,
  ) {
    format {
      id
    }
  }
}
`;

export const DELETE_FORMAT = `
mutation Delete($id: Int!)
{
  deleteFormat(id: $id)
  {
    id
  }
}
`;

export const MOVCASH = `
{
  movcash {
    id
    idx
    uid
    description
    accountNumber
    originalCurrency
    numberOfTitles
    investment
    date
    amountOriginalCurrency
    symbolCusip
    type
    typeM
    holding
    origin
    reference {
      id
    }
    declaration {
      id
    }
  }
}
`;

export const SET_MOVCASH = `
mutation Set(
  $id: Int!,
  $idx: Int,
  $uid: String,
  $description: String,
  $accountNumber: String,
  $originalCurrency: String,
  $numberOfTitles: String,
  $investment: String,
  $date: String,
  $amountOriginalCurrency: String,
  $symbolCusip: String,
  $type: String,
  $typeM: String,
  $holding: String,
  $origin: String,
  $reference: Int,
  $declaration: Int,
)
{
  setMovcash(
    id: $id,
    idx: $idx,
    uid: $uid,
    description: $description,
    accountNumber: $accountNumber,
    originalCurrency: $originalCurrency,
    numberOfTitles: $numberOfTitles,
    investment: $investment,
    date: $date,
    amountOriginalCurrency: $amountOriginalCurrency,
    symbolCusip: $symbolCusip,
    type: $type,
    typeM: $typeM,
    holding: $holding,
    origin: $origin,
    reference: $reference,
    declaration: $declaration,
  ) {
    movcash {
      id
      idx
      uid
      description
      accountNumber
      originalCurrency
      numberOfTitles
      investment
      date
      amountOriginalCurrency
      symbolCusip
      type
      typeM
      holding
      origin
      reference {
        id
      }
      declaration {
        id
      }
    }
  }
}
`;

export const SAVE_MOVCASH = `
mutation Save(
  $idx: Int!,
  $uid: String!,
  $description: String!,
  $accountNumber: String!,
  $originalCurrency: String!,
  $numberOfTitles: String!,
  $investment: String!,
  $date: String!,
  $amountOriginalCurrency: String!,
  $symbolCusip: String!,
  $type: String!,
  $typeM: String!,
  $holding: String,
  $origin: String!,
  $reference: Int,
  $declaration: Int!,
)
{
  saveMovcash(
    idx: $idx,
    uid: $uid,
    description: $description,
    accountNumber: $accountNumber,
    originalCurrency: $originalCurrency,
    numberOfTitles: $numberOfTitles,
    investment: $investment,
    date: $date,
    amountOriginalCurrency: $amountOriginalCurrency,
    symbolCusip: $symbolCusip,
    type: $type,
    typeM: $typeM,
    holding: $holding,
    origin: $origin,
    reference: $reference,
    declaration: $declaration,
  ) {
    movcash {
      id
    }
  }
}
`;

export const DELETE_MOVCASH = `
mutation Delete($id: Int!)
{
  deleteMovcash(id: $id)
  {
    id
  }
}
`;

export const MOVCASH_NAC = `
{
  movcashNac {
    id
    idx
    uid
    currency
    issuer
    rfc
    documentType
    documentNum
    typeMn
    concept
    date
    amount
    notes
    declaration {
      id
    }
  }
}
`;

export const SET_MOVCASH_NAC = `
mutation Set(
  $id: Int!,
  $idx: Int,
  $uid: String,
  $currency: String,
  $issuer: String,
  $rfc: String,
  $documentType: String,
  $documentNum: String,
  $typeMn: String,
  $concept: String,
  $date: String,
  $amount: String,
  $notes: String,
  $declaration: Int,
)
{
  setMovcashNac(
    id: $id,
    idx: $idx,
    uid: $uid,
    currency: $currency,
    issuer: $issuer,
    rfc: $rfc,
    documentType: $documentType,
    documentNum: $documentNum,
    typeMn: $typeMn,
    concept: $concept,
    date: $date,
    amount: $amount,
    notes: $notes,
    declaration: $declaration,
  ) {
    movcashNac {
      id
      idx
      uid
      currency
      issuer
      rfc
      documentType
      documentNum
      typeMn
      concept
      date
      amount
      notes
      declaration {
        id
      }
    }
  }
}
`;

export const SAVE_MOVCASH_NAC = `
mutation Save(
  $idx: Int!,
  $uid: String!,
  $currency: String!,
  $issuer: String!,
  $rfc: String!,
  $documentType: String!,
  $documentNum: String!,
  $typeMn: String!,
  $concept: String!,
  $date: String!,
  $amount: String!,
  $notes: String!,
  $declaration: Int!,
)
{
  saveMovcashNac(
    idx: $idx,
    uid: $uid,
    currency: $currency,
    issuer: $issuer,
    rfc: $rfc,
    documentType: $documentType,
    documentNum: $documentNum,
    typeMn: $typeMn,
    concept: $concept,
    date: $date,
    amount: $amount,
    notes: $notes,
    declaration: $declaration,
  ) {
    movcashNac {
      id
    }
  }
}
`;

export const DELETE_MOVCASH_NAC = `
mutation Delete($id: Int!)
{
  deleteMovcashNac(id: $id)
  {
    id
  }
}
`;

export const PAGE = `
{
  page {
    id
    refName
    refIndex
    rawText
    rawJson
    jsonReduced
    width
    height
    precision
    image {
      id
      url
    }
    document {
      id
    }
  }
}
`;

export const SET_PAGE = `
mutation Set(
  $id: Int!,
  $refName: String,
  $refIndex: Int,
  $rawText: String,
  $rawJson: String,
  $jsonReduced: String,
  $width: Float,
  $height: Float,
  $precision: Float,
  $image: Int,
  $document: Int,
)
{
  setPage(
    id: $id,
    refName: $refName,
    refIndex: $refIndex,
    rawText: $rawText,
    rawJson: $rawJson,
    jsonReduced: $jsonReduced,
    width: $width,
    height: $height,
    precision: $precision,
    image: $image,
    document: $document,
  ) {
    page {
      id
      refName
      refIndex
      rawText
      rawJson
      jsonReduced
      width
      height
      precision
      image {
        id
      }
      document {
        id
      }
    }
  }
}
`;

export const SAVE_PAGE = `
mutation Save(
  $refName: String!,
  $refIndex: Int!,
  $rawText: String,
  $rawJson: String!,
  $jsonReduced: String!,
  $width: Float!,
  $height: Float!,
  $precision: Float!,
  $image: Int,
  $document: Int!,
)
{
  savePage(
    refName: $refName,
    refIndex: $refIndex,
    rawText: $rawText,
    rawJson: $rawJson,
    jsonReduced: $jsonReduced,
    width: $width,
    height: $height,
    precision: $precision,
    image: $image,
    document: $document,
  ) {
    page {
      id
    }
  }
}
`;

export const DELETE_PAGE = `
mutation Delete($id: Int!)
{
  deletePage(id: $id)
  {
    id
  }
}
`;

export const PROCESS = `
{
  process {
    id
    uuid
    type
    status
    params
    result
    error
    execTime
    declaration {
      id
    }
  }
}
`;

export const SET_PROCESS = `
mutation Set(
  $id: Int!,
  $uuid: String,
  $type: String,
  $status: String,
  $params: GenericScalar,
  $result: GenericScalar,
  $error: GenericScalar,
  $execTime: Int,
  $declaration: Int,
)
{
  setProcess(
    id: $id,
    uuid: $uuid,
    type: $type,
    status: $status,
    params: $params,
    result: $result,
    error: $error,
    execTime: $execTime,
    declaration: $declaration,
  ) {
    process {
      id
      uuid
      type
      status
      params
      result
      error
      execTime
      declaration {
        id
      }
    }
  }
}
`;

export const SAVE_PROCESS = `
mutation Save(
  $uuid: String!,
  $type: String!,
  $status: String!,
  $params: GenericScalar,
  $result: GenericScalar,
  $error: GenericScalar,
  $execTime: Int!,
  $declaration: Int!,
)
{
  saveProcess(
    uuid: $uuid,
    type: $type,
    status: $status,
    params: $params,
    result: $result,
    error: $error,
    execTime: $execTime,
    declaration: $declaration,
  ) {
    process {
      id
    }
  }
}
`;

export const DELETE_PROCESS = `
mutation Delete($id: Int!)
{
  deleteProcess(id: $id)
  {
    id
  }
}
`;

export const REGION = `
{
  region {
    id
    x
    y
    width
    height
    page {
      id
    }
    table {
      id
    }
  }
}
`;

export const SET_REGION = `
mutation Set(
  $id: Int!,
  $x: Float,
  $y: Float,
  $width: Float,
  $height: Float,
  $page: Int,
  $table: Int,
)
{
  setRegion(
    id: $id,
    x: $x,
    y: $y,
    width: $width,
    height: $height,
    page: $page,
    table: $table,
  ) {
    region {
      id
      x
      y
      width
      height
      page {
        id
      }
      table {
        id
      }
    }
  }
}
`;

export const SAVE_REGION = `
mutation Save(
  $x: Float!,
  $y: Float!,
  $width: Float!,
  $height: Float!,
  $page: Int!,
  $table: Int!,
)
{
  saveRegion(
    x: $x,
    y: $y,
    width: $width,
    height: $height,
    page: $page,
    table: $table,
  ) {
    region {
      id
    }
  }
}
`;

export const DELETE_REGION = `
mutation Delete($id: Int!)
{
  deleteRegion(id: $id)
  {
    id
  }
}
`;

export const TABLE = `
{
  table {
    id
    name
    idx
    refRowIndex
    refColIndex
    refColOffset
    defRegionLeft
    defRegionTop
    defRegionRight
    defRegionBottom
    defRegionFooter
    defRegionWidth
    defRegionHeight
    topRegionReference
    topLimitRegionReference
    bottomRegionReference
    bottomLimitRegionReference
    hidden
    opts
    format {
      id
    }
  }
}
`;

export const SET_TABLE = `
mutation Set(
  $id: Int!,
  $name: String,
  $idx: Int,
  $refRowIndex: String,
  $refColIndex: String,
  $refColOffset: Int,
  $defRegionLeft: Float,
  $defRegionTop: Float,
  $defRegionRight: Float,
  $defRegionBottom: Float,
  $defRegionFooter: Float,
  $defRegionWidth: Float,
  $defRegionHeight: Float,
  $topRegionReference: String,
  $topLimitRegionReference: String,
  $bottomRegionReference: String,
  $bottomLimitRegionReference: String,
  $hidden: Boolean,
  $opts: String,
  $format: Int,
)
{
  setTable(
    id: $id,
    name: $name,
    idx: $idx,
    refRowIndex: $refRowIndex,
    refColIndex: $refColIndex,
    refColOffset: $refColOffset,
    defRegionLeft: $defRegionLeft,
    defRegionTop: $defRegionTop,
    defRegionRight: $defRegionRight,
    defRegionBottom: $defRegionBottom,
    defRegionFooter: $defRegionFooter,
    defRegionWidth: $defRegionWidth,
    defRegionHeight: $defRegionHeight,
    topRegionReference: $topRegionReference,
    topLimitRegionReference: $topLimitRegionReference,
    bottomRegionReference: $bottomRegionReference,
    bottomLimitRegionReference: $bottomLimitRegionReference,
    hidden: $hidden,
    opts: $opts,
    format: $format,
  ) {
    table {
      id
      name
      idx
      refRowIndex
      refColIndex
      refColOffset
      defRegionLeft
      defRegionTop
      defRegionRight
      defRegionBottom
      defRegionFooter
      defRegionWidth
      defRegionHeight
      topRegionReference
      topLimitRegionReference
      bottomRegionReference
      bottomLimitRegionReference
      hidden
      opts
      format {
        id
      }
    }
  }
}
`;

export const SAVE_TABLE = `
mutation Save(
  $name: String,
  $idx: Int,
  $refRowIndex: String!,
  $refColIndex: String!,
  $refColOffset: Int!,
  $defRegionLeft: Float!,
  $defRegionTop: Float!,
  $defRegionRight: Float!,
  $defRegionBottom: Float!,
  $defRegionFooter: Float!,
  $defRegionWidth: Float!,
  $defRegionHeight: Float!,
  $topRegionReference: String,
  $topLimitRegionReference: String,
  $bottomRegionReference: String,
  $bottomLimitRegionReference: String,
  $hidden: Boolean,
  $opts: String,
  $format: Int!,
)
{
  saveTable(
    name: $name,
    idx: $idx,
    refRowIndex: $refRowIndex,
    refColIndex: $refColIndex,
    refColOffset: $refColOffset,
    defRegionLeft: $defRegionLeft,
    defRegionTop: $defRegionTop,
    defRegionRight: $defRegionRight,
    defRegionBottom: $defRegionBottom,
    defRegionFooter: $defRegionFooter,
    defRegionWidth: $defRegionWidth,
    defRegionHeight: $defRegionHeight,
    topRegionReference: $topRegionReference,
    topLimitRegionReference: $topLimitRegionReference,
    bottomRegionReference: $bottomRegionReference,
    bottomLimitRegionReference: $bottomLimitRegionReference,
    hidden: $hidden,
    opts: $opts,
    format: $format,
  ) {
    table {
      id
    }
  }
}
`;

export const DELETE_TABLE = `
mutation Delete($id: Int!)
{
  deleteTable(id: $id)
  {
    id
  }
}
`;

export const TEAM = `
{
  team {
    id
    name
    members {
      id
    }
  }
}
`;

export const SET_TEAM = `
mutation Set(
  $id: Int!,
  $name: String,
  $members: [Int],
)
{
  setTeam(
    id: $id,
    name: $name,
    members: $members,
  ) {
    team {
      id
      name
      members {
        id
      }
    }
  }
}
`;

export const SAVE_TEAM = `
mutation Save(
  $name: String!,
  $members: [Int],
)
{
  saveTeam(
    name: $name,
    members: $members,
  ) {
    team {
      id
    }
  }
}
`;

export const DELETE_TEAM = `
mutation Delete($id: Int!)
{
  deleteTeam(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    rol
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $rol: String,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      rol
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $rol: String!,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;