import React from 'react';
import cx from 'classnames';
import styles from 'styles/css/scan/selection/Selection.module.css';


function Region(props)
{
  const {
    regionProps, defBank, defFormat, region, banks, page,
    onSelectTable, onDeleteRegion, onDeleteLocalRegion } = props;

  if (regionProps.isChanging) return <div></div>;

  if (region.data.id != null)
    return (
      <div className={cx(styles.region, styles.saved)}>
        <button className={styles.numId}>{region.data.index + 1}</button>
        <span className={styles.legend}>Tabla registrada</span>
        <a href="javascript: void(0)"
          onClick={() => onDeleteRegion(region.data.id)}
          className={cx(styles.btn, styles.delete)}>
          <span className={styles.text}>Borrar</span>
        </a>
      </div>
    );

  return (
    <div className={styles.region}>
      {
        defBank != 0 ?
          <select
            value={region.tableId}
            onChange={(e) => onSelectTable(page, regionProps.index, e.target.value)}>
            <option>Selecciona un tipo de tabla</option>
            {
              banks.map(b =>
                b.formats
                  .filter(f => f.id == defFormat && b.id == defBank)
                  .map(f => {
                    let tableSorted = [...f.tables]
                    return tableSorted
                      .sort((t1, t2) => t1.id - t2.id)
                      .map(t =>
                        <option value={t.id}>{t.name}</option>)
                  })                
              )
            }
          </select> :
          <select> <option>Selecciona un banco (Barra superior)</option> </select>
      }
      <button className={styles.delete}
        onClick={() => onDeleteLocalRegion(page, regionProps.index)}>Borrar</button>
    </div>
  );
}

export default Region;