import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Switch, NavLink, Route, Redirect } from 'react-router-dom';
import Documents from 'components/documents/Documents';
import Transactions from 'components/transactions/Transactions';
import TransactionsNac from 'components/transactions_nac/TransactionsNac';
import Calcs from 'components/calcs/Calcs';
import { Loading } from "seed/helpers";
import { useQuery } from 'seed/gql';
import styles from 'styles/css/navigation/Content.module.css';
import { IS_PROD, IS_EARLY_ADOPTER } from 'settings';

const TEAMS = `
{
  teams {
    name
  }
}
`

function Content(props)
{
  const { path, url } = props.match;
  const userId = sessionStorage.getItem("id");

  const qTeams = useQuery(TEAMS, `members=${userId}`);
  if (qTeams.data == null) return <Loading />
  if (qTeams.loading) return <Loading />

  const teamName = qTeams.data.teams[0].name
  const isEarlyAdopter = IS_EARLY_ADOPTER(teamName)
  const showFullFeatures = !IS_PROD || isEarlyAdopter;

  return (
    <div className={styles.module}>
     
     <div className={styles.tabnav}>
        <div className={styles.wrapper}>
          <ul>
            <NavLink to={`${url}/documents`} activeClassName={styles.activeLink}>
              <li>EC del extranjero</li>
            </NavLink>
            <NavLink to={`${url}/transactions`} activeClassName={styles.activeLink}>
              <li>Movimientos del extranjero</li>
            </NavLink>
            <NavLink to={`${url}/transactions_nac`} activeClassName={styles.activeLink}>
              <li>Ingresos nacionales</li>
            </NavLink>
            <NavLink to={`${url}/calcs`} activeClassName={styles.activeLink}>
              <li>Cálculo</li>
            </NavLink>
          </ul>
        </div>
      </div>
        <div className={styles.content}>
          <Switch>
            <Route path={[`${path}/documents`, `${path}/details`]} component={Documents} />
            <Route path={`${path}/transactions`} component={Transactions} />
            <Route path={`${path}/transactions_nac`} component={TransactionsNac} />
            <Route path={`${path}/calcs`} component={Calcs} />
            <Redirect to={`${url}/documents`} />
          </Switch>
        </div>
    </div>
  );
}

export default Content;