const DOCUMENT = `
{
  document {
    file {
      url
    }
  }
}
`

const PAGES = `
{
  pages {
    rawText
    jsonReduced
    width
    height
    image {
      url
    }
  }
}
`

const BANKS = `
{
  banks {
    name
    formats
    {
      name
      refText
      tables {
        name
        topLimitRegionReference
        topRegionReference
        bottomRegionReference
        bottomLimitRegionReference
        defRegionLeft
        defRegionTop
        defRegionBottom
        defRegionFooter
        defRegionWidth
        opts
        columns {
          index
          name
        }
      }
      previewImage {
        url
      }
    }
  }
}
`

const REGIONS = `
{
  regions {
    x
    y
    width
    height
    page {
      image {
        url
      }
    }
    table {
      name
      format{
        name
        bank {
          name
        }
      }
    }
  }
}`

const loadRegions = (data) =>
{
  let pages = []
  let idx = 0;
  let defBankId = 0;
  let defFormatId = 0;
  for (let r of data.regions) {
    if (pages["p" + r.page.id] == null) pages["p" + r.page.id] = []
    pages["p" + r.page.id].push({
      data: {
        index: idx, id: r.id, regionStyle: {
          border: "3px solid #a0ddb0"
        },
        init: {
          x: r.x,
          y: r.y,
          width: r.width,
          height: r.height,
        },
        rescan: false
      },
      x: r.x,
      y: r.y,
      width: r.width,
      height: r.height,
      isChanging: false,
      isNew: false,
      pageId: r.page.id,
      tableId: r.table.id
    })
    defBankId = r.table.format.bank.id
    defFormatId = r.table.format.id
    idx++;
  }
  return {
    defBank: defBankId,
    defFormat: defFormatId,
    pages: pages
  }
}

const getScanRegions = pages =>
{
  let regions = [];
  for (let page in pages) {
    for (let region of pages[page]) {
      if (region.data.id != null && !region.data.rescan) continue;
      let res = {
        id: region.data.id,
        x: region.x,
        y: region.y,
        width: region.width,
        height: region.height,
        page_id: region.pageId,
        table_id: region.tableId
      }
      if (region.tableId == null) {
        alert("Selecciona un tipo de tabla")
        return [];
      }
      regions.push(res)
    }
  }
  return regions
}



export { DOCUMENT, PAGES, BANKS, REGIONS, loadRegions, getScanRegions }