const getNumFormat = (data, decimalCount = 2) =>
{
  try {
    if (data == "") return data
    let num = parseFloat(data);
    if (isNaN(num)) return ""
    return new Intl.NumberFormat('ja-JP').format(num);
  } catch (e){
    return data
  }
}

function padLeft(value, length)
{
  return (value.toString().length < length) ? padLeft("0" + value, length) :
    value;
}

const getDateFormat = (date) =>
{
  try {
    let parts = date.split("/")
    if (parts.length != 3) return date;
    return padLeft(parts[0], 2) + "/" + padLeft(parts[1], 2) + "/" + parts[2];
  } catch (e){
    return date
  }
}

const getDateExcelFormat = (date) =>
{
  try {
    let parts = date.split("/")
    if (parts.length != 3) return date;
    let date1 = new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]), 0, 0, 0, 0));
    return new Date(date1.toUTCString());
  } catch (e){
    return date
  }
}


export { getNumFormat, getDateFormat, getDateExcelFormat }