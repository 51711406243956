/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "banks": "bank",
  "bank": "bank",
  "bankAccounts": "bankAccount",
  "bankAccount": "bankAccount",
  "calcs": "calc",
  "calc": "calc",
  "calcKpis": "calcKpi",
  "calcKpi": "calcKpi",
  "calcProcesses": "calcProcess",
  "calcProcess": "calcProcess",
  "cells": "cell",
  "cell": "cell",
  "clients": "client",
  "client": "client",
  "columns": "column",
  "column": "column",
  "currencies": "currency",
  "currency": "currency",
  "declarations": "declaration",
  "declaration": "declaration",
  "documents": "document",
  "document": "document",
  "dollarParities": "dollarParity",
  "dollarParity": "dollarParity",
  "entities": "entity",
  "entity": "entity",
  "equivalences": "equivalence",
  "equivalence": "equivalence",
  "formats": "format",
  "format": "format",
  "movcashes": "movcash",
  "movcash": "movcash",
  "movcashNacs": "movcashNac",
  "movcashNac": "movcashNac",
  "pages": "page",
  "page": "page",
  "processes": "process",
  "process": "process",
  "regions": "region",
  "region": "region",
  "tables": "table",
  "table": "table",
  "teams": "team",
  "team": "team",
  "users": "user",
  "user": "user",
};