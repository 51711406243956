import React, { useState } from 'react';
import cx from 'classnames';
import $ from 'jquery';
import { useQuery } from 'seed/gql';
import { Link, Route } from 'react-router-dom';
import Pages from 'components/documents/Pages';
import Formats from 'components/documents/Formats';
import { Modal } from 'seed/helpers';
import Table from 'components/scan/table/Table';
import DocUpload from 'components/documents/Upload';
import NetDocs from 'components/documents/netdocs/Netdocs';
import Selection from 'components/scan/selection/Selection';
import styles from 'styles/css/documents/Documents.module.css';

const DOCUMENTS = `
{
  documents {
    pages {
      regions { 
        table {
          format {
            bank {
              name
            }
          }
        }
      }
    }
  } 
}
`

function Documents(props) {

  const { path, url } = props.match;
  const { declaration_id } = props.match.params;

  const [filterBank, setFilterBank] = useState(0)
  const qDocuments = useQuery(DOCUMENTS, `declaration=${declaration_id}`);
  const { documents = [] } = qDocuments.data;

  const banksL = {}
  let banks = [...documents]
    .map(doc => {
      let docT = Object.assign({}, doc)
      let regions = [];
      docT.pages.map(
        (page) => page.regions.map(
          (region) => regions.push(region)))
      docT.regions = regions;
      return docT
    })
    .map(doc => {
      return doc.regions.length > 0 ?
        {
          id: doc.regions[0].table.format.bank.id,
          name: doc.regions[0].table.format.bank.name
        } : {}
    })
    .filter(bank => bank.id != null)
    .map(bank => {
      banksL[bank.id] = true
      return bank;
    })
    .filter(bank => {
      const unique = banksL[bank.id] == true;
      banksL[bank.id] = false;
      return unique;
    })


  const width = $(window).width();
  const height = $(window).height();

  const UploadModal = props =>
    <Modal width={480} height={400}{...props} animation={""} >
      <DocUpload {...props} />
    </Modal>

  const TableModal = props =>
    <Modal width={width - 40} height={height - 40}{...props} animation={""} >
      <Table {...props} />
    </Modal>

  const SelectionModal = props =>
    <Modal width={width - 40} height={height - 40}{...props} animation={""} >
      <Selection {...props} />
    </Modal>

  const NetdocsModal = props =>
    <Modal width={400} height={480}{...props} animation={""} >
      <NetDocs {...props} />
    </Modal>

  const FormatModal = props =>
    <Modal width={600} height={520}{...props} animation={""} >
      <Formats {...props} />
    </Modal>

  const onBankChange = (e) =>
    setFilterBank(e.target.value)

  return (
    <div className={styles.module}>

      <div className={styles.cardNav}>
        <div className={styles.banks}>
          <select onChange={onBankChange}>
            <option value="">Todas los bancos</option>
            <option value={-1}>Sin registros</option>
            {
              banks.map(b =>
                <option value={b.id}>{b.name}</option>)
            }
          </select>

        </div>
        <Link to={`${url}/formats`} className={styles.formatsContainer}>
          <label className={cx(styles.formats)}>
            <button className={cx(styles.btn)}>
              Formatos compatibles
            </button>
          </label>
        </Link>

        <Link to={`${url}/form`}>
          <label className={cx(styles.call)}>
            <button className={cx(styles.btn, styles.btnGreen)}>
              <i className="fas fa-upload"></i>
              Subir documento
              </button>
          </label>
        </Link>
        {/*
        <Link to={`${url}/nd`}>
          <label className={cx(styles.call)}>
            <button className={cx(styles.btn, styles.btnGreen)}>
              <i className="fas fa-upload"></i>
              Subir desde ND
              </button>
          </label>
        </Link>
        */}

      </div>

      <div className={styles.cardReduce}>
        <Pages
          {...props}
          filterBank={filterBank} />
      </div>

      <Route path={`${path}/:document_id(\\d+)`}
        component={SelectionModal} />
      <Route path={`${path}/:document_id(\\d+)/:table_id(\\d+)`}
        component={TableModal} />
      <Route path={`${path}/form`}
        component={UploadModal} />
      <Route path={`${path}/nd`}
        component={NetdocsModal} />
      <Route path={`${path}/formats`}
        component={FormatModal} />
    </div>
  );
}

export default Documents;