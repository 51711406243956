import React, { useState } from 'react';
import cx from 'classnames';
import { useGet,usePost } from 'seed/api'
import { Formik } from 'formik';
import { Loading } from 'seed/helpers'
import styles from 'styles/css/documents/netdocs/Netdocs.module.css';

function NetDocs(props)
{
  const redirect_href = "https://vault.netvoyage.com/neWeb2/OAuth.aspx?client_id=AP-NQ81QUFR&scope=full&response_type=token&redirect_uri=https://smp.chevez.com/api/users/nd_sp"
  if (sessionStorage.getItem('tokenND') == null) {
    window.location.replace(redirect_href)
  }
  const [cDown, qDown] = usePost("/documents/netdocs_download", {
    onCompleted: data =>
    {
      console.log(data);
    }
  });
  const qDocs = useGet("/documents/netdocs", { token: sessionStorage.getItem('tokenND') }, {
    onError: data => {
      window.location.replace(redirect_href);
    }
  });
  
  if (qDocs.loading) return <Loading />
  if (qDocs.error) return "Error"
  if (qDown.loading) return <Loading />
  if (qDown.error) return "Error";

  const downDoc = docId =>
  {
    if (window.confirm("¿Estás seguro?"))
      cDown({ token: sessionStorage.getItem('tokenND'), document_id: docId });
  }


  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Subir desde NetDocuments</h4>
      </div>

      <div className={styles.paper, styles.form}>
        <Formik
          
          render={f => (

            
            <div className={cx(styles.list)}>
              {
                qDocs.data.Documents.map((doc, idx) =>
                  <div className={styles.item} key={idx}>
                    <div className={styles.info}>
                      <p className={styles.name}>{doc.document_name}</p>
                      <p className={styles.date}>{doc.client_name}</p>
                    </div>
                    <div className={styles.toRigth}>
                      <a href="javascript: void(0)" onClick={() => downDoc(doc.document_id)} className={cx(styles.btn, styles.toRigth)}>
                        <span className={styles.text}>Descargar</span>
                      </a>
                    </div>
                  </div>)
              }
            </div>
          
          )}
        />
      </div>
    </div>
  );
}

export default NetDocs;
