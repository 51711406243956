import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useQuery } from 'seed/gql';
import { yearFormat } from 'components/util/Format';
import { buildQuery } from 'components/declarations/List.lhelpers';
import { Loading } from 'seed/helpers';
import styles from 'styles/css/declarations/List.module.css';


const DECLARATIONS = `
{
  declarations
  { 
    internalToken
    referenceDate
    client {
      user {
        firstName
        lastName
      }
    }
  }
}
`

function List(props)
{
  const { url } = props.match;
  const { search = {} } = props;

  const userId = sessionStorage.getItem("id");
  const declarationId = localStorage.getItem("declarationId")
  let query = userId != 1 ? `team.members=${userId}` : "";
  query = buildQuery(query, search);

  const qDeclarations = useQuery(DECLARATIONS, query, {
    onCompleted: data => {
        let authorized = declarationId == null;
        for (let d of data.declarations)
          if (d.id == declarationId)
            authorized = true
        if (!authorized){
          localStorage.removeItem("declarationId");
          window.location.href = url;
        }
      }
    }
  );

  if (qDeclarations.loading) return <Loading />;
  if (qDeclarations.error) return `Error! ${qDeclarations.message}`;

  let { declarations = [] } = qDeclarations.data
  declarations
    = declarations
      .filter(d => d.id != 1)
      .sort((a, b) => (new Date(b.referenceDate)).getTime() - (new Date(a.referenceDate)).getTime())

  const onClickDeclaration = declarationId => 
    localStorage.setItem("declarationId", declarationId)

  return (
    <div className={styles.module}>
      {declarations.length == 0 ?
        <div className={styles.emptyList}><p>Sin declaraciones</p></div> : null}
      <div className={styles.cardList}>
        {
          declarations.map(declaration =>
            <NavLink to={`${url}/${declaration.id}`}
              onClick={() => onClickDeclaration(declaration.id)}
              activeClassName={styles.active} key={declaration.id}>
              <div className={styles.item}>
                <div className={styles.idContainer}>
                  <p className={styles.id}>
                    {declaration.internalToken}<br/>
                    <label className={styles.year}>{yearFormat(declaration.referenceDate)}</label>
                  </p>
                </div>
                <Link to={`${url}/${declaration.id}/details`} className={styles.edit}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </div>
            </NavLink>
          )
        }
      </div>
    </div>
  )
}

export default List;