import React, { useState } from 'react';
import cx from 'classnames';
import { useDetail } from 'seed/gql';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from 'styles/css/navigation/Topnav.module.css';
import { SERVER_URL } from 'settings';

const USER = `
{
  user {
    firstName
    lastName
    rol
  }
}
`

function Topnav(props)
{
  const [state, setState] = useState({});
  const qAuthUser = useDetail(USER, sessionStorage.getItem("id"));

  const { user = {} } = qAuthUser.data;
  let rol = ""
  if (user.rol == "PARTNER") rol = "Socio";
  if (user.rol == "ASSOCIATE") rol = "Asociado";
  if (user.rol == "STAFF") rol = "Staff";


  let username;
  if (qAuthUser.loading && !qAuthUser.error) username = <p>Cargando...</p>
  else if (qAuthUser.error) username = <p>Usuario Chevez</p>
  else username =
    <p>
      {user.firstName} {user.lastName}
      <br />
      <span className={styles.rol}>{rol}
        <i className="fas fa-caret-down"></i>
      </span>
    </p>


  const openOptionMenu = e =>
    setState({ optionMenu: e.currentTarget });

  const closeOptionMenu = () =>
    setState({ optionMenu: null });

  const onClickLogout = () =>
  {
    props.history.push("/logout")
    closeOptionMenu()
  }

  const onClickManual = () =>
  {
    window.location.href = `https://chevezruiz-my.sharepoint.com/:b:/g/personal/erick_rivas_chevez_com_mx/EenAmD_utIhKgmOGxXVDdS0B0IXsSa63tPUoVvfBuJ5qkQ?e=t9pAF6`;
    closeOptionMenu()
  }

  return (
    <div className={styles.module}>
      <div className={styles.topnav}>
        <div className={styles.navLeft}>
          <div className={styles.dots}></div>
          <div className={styles.logo}>
            <img src={'/resources/icons/chevez.png'} alt="Chevez Ruiz Zamarripa" />
          </div>
          <div>
            <h5>Sistema de Declaraciones Patrimoniales</h5>
          </div>
        </div>

        <div className={styles.user} onClick={openOptionMenu}>
          <img src="https://www.1spotmedia.com/assets/theme/rjr/images/profile.png" alt="ProfilePic" />
          {username}
        </div>

        <Menu
          anchorEl={state.optionMenu}
          open={Boolean(state.optionMenu)}
          onClose={closeOptionMenu}>
          <MenuItem onClick={onClickManual}>Manual de Usuario</MenuItem>
          <MenuItem onClick={onClickLogout}>Cerrar sesión</MenuItem>
        </Menu>

      </div>
    </div>
  );
}

export default Topnav;