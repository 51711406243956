import React from 'react';
import cx from 'classnames';
import { useQuery } from 'seed/gql'
import Workbook from 'react-excel-workbook'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/scan/selection/Download.module.css';


const REGIONS = `
{
  regions {
    page {
      refIndex
      document{
        file {
          url
        }
      }
    }
    table {
      name
      format {
        bank{
          name
        }
      }
      columns {
        name
      }
    }
    cells {
      row
      value
      col {
        index
      }
    }
  }
}`

function Download(props)
{
  const { type, declaration_id, document_id } = props.match.params;
  const qRegions = useQuery(REGIONS, type == "declaration" ?
    `page.document.declaration=${declaration_id}` :
    `page.document=${document_id}`);

  const { regions = [] } = qRegions.data;
  
  if (qRegions.loading)
    return <div className={styles.loading}><Loading /></div>

  const workbook = {}
  for (let region of regions) {
    if (workbook[region.table.id] == null) {
      workbook[region.table.id] = {
        name: region.table.format.bank.initials + "(" + region.page.document.id + ")" + " - " + region.table.name,
        data: [],
        columns: []
      }
    }
    for (let column of region.table.columns) {
      workbook[region.table.id].columns.push({
        id: column.id,
        name: column.name
      })
    }

    const cells = region.cells;
    cells.sort((a, b) => (a.row - b.row));
    let currentRow = 0;
    for (let j = 0; j < cells.length; j++) {
      if (currentRow != cells[j].row) {
        let row = {};
        currentRow = cells[j].row;
        for (let i = j; i < cells.length && currentRow == cells[i].row; i++) {
          row[cells[i].col.id] = cells[i].value;
          j = i;
        }
        workbook[region.table.id].data.push(row);
      }

    }
  }

  const res = [];
  for (let wb in workbook)
    res.push(workbook[wb])

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4>Descargar escaneo</h4>
      </div>
      <div className={styles.paper}>
        
        <Workbook filename="datos.xlsx" element={
          <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            <span className={styles.text}>Descargar</span>
          </button>}>
          {
            res.map(wb =>
              <Workbook.Sheet data={wb.data} name={wb.name.length > 25 ? wb.name.substring(0, 25) + Math.floor(Math.random() * 100)  : wb.name + Math.floor(Math.random() * 100)}>
                {
                  wb.columns.map(column =>
                    <Workbook.Column label={column.name} value={column.id} />
                  )
                }
              </Workbook.Sheet>
            )
          }
        </Workbook>
      </div>
    </div>
  );
}

export default Download;