

import * as React from 'react';
import { useState } from 'react';
import cx from 'classnames';

import Login from 'components/auth/Login'
import Logout from 'components/auth/Logout'
import Analytics from 'components/navigation/Analytics'
import Home from 'components/Home'
import LoginAuth from 'components/auth/LoginAuth'
import LoginAuthND from 'components/auth/LoginAuthND'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import styles from 'styles/css/App.module.css';

class App extends React.Component
{
  render()
  {
    return (
      <div className={cx(styles.module)}>
        <Router>
          <Switch>
            <Route path="/login/auth" component={LoginAuth} />
            <Route path="/login/authnd" component={LoginAuthND} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/app" component={Home} />
            <Redirect to='/app' />
          </Switch>
          <Route path="/" component={Analytics} />
        </Router>
      </div>
    );
  }

  constructor(props)
  {
    super(props);
    this.state = {};
  }
}

export default App;