const getRegions = (pages, table) =>
{
  let res = [];

  let lastValid = -10;
  for (let i = 0; i < pages.length; i++) {

    let rawText = pages[i].rawText;
    let rawJson = pages[i].jsonReduced;
    let iniPos = isValidPage(rawText, rawJson, table, lastValid == i - 1);

    //DEBUG
    console.log(`DEBUUUG P(${i + 1})`);
    console.log(rawText);
    console.log("===")

    if (iniPos == null) continue;

    lastValid = i;
    let topReference = table.topRegionReference;
    let bottomReference = table.bottomRegionReference;
    let opts = table.opts ?? "";

    if (opts.includes("MULTIPLE=true")) {

      let lastIniPos = iniPos.y;
      let variable = true
      while (variable) {
        let bottomPos = getConditionalPos(rawText, rawJson, bottomReference, lastIniPos);
        let topPos = getConditionalPos(rawText, rawJson, topReference, lastIniPos, bottomPos ? bottomPos.y : Number.MAX_SAFE_INTEGER);
        if (bottomPos != null) lastIniPos = bottomPos.y + 10;
        else lastIniPos = Number.MAX_SAFE_INTEGER;

        if (topPos == null) break;
        if (bottomPos != null)
          lastValid = 0;
        let regioon = getRegion(i, topPos, bottomPos, table, pages);
        if (regioon != null)
          res.push(regioon)
      }

    } else {
      let bottomPos = getConditionalPos(rawText, rawJson, bottomReference, iniPos.y);
      let topPos = getConditionalPos(rawText, rawJson, topReference, iniPos.y, bottomPos ? bottomPos.y : Number.MAX_SAFE_INTEGER);
      if (bottomPos != null)
        lastValid = -10;
      let regioon = getRegion(i, topPos, bottomPos, table, pages);
      if (regioon != null)
        res.push(regioon)
    }
  }
  return res;
}

const getRegion = (i, topPos, bottomPos, table, pages) =>
{
  if (topPos != null) {

    let y = topPos.y / pages[i].height * 100;
    y = y + table.defRegionTop < 100 ? y + table.defRegionTop : y;
    let h = bottomPos != null && bottomPos.y > topPos.y ?
      (bottomPos.y / pages[i].height * 100) - y - 1 - table.defRegionBottom :
      100 - y - table.defRegionFooter;
    if (h < 1) return null;
    return {
      data: {
        regionStyle: {
          border: "2px dashed #50b0dd"
        }
      },
      x: table.defRegionLeft,
      y: y,
      width: table.defRegionWidth,
      height: y + h < 100 ? h : 97 - y,
      isChanging: false,
      isNew: true,
      pageIdx: i,
      pageId: parseInt(pages[i].id),
      tableId: parseInt(table.id)
    };
  }
}


const isValidPage = (rawText, rawJson, table, includeTop) =>
{
  //Check reference
  let topLimitReference = table.topLimitRegionReference;
  let topReference = table.topRegionReference;
  let condition = includeTop ? topLimitReference + "||" + topReference : topLimitReference
  let refPos = getConditionalPos(rawText, rawJson, condition);
  return refPos;
}

const getConditionalText = (rawText, find) =>
{
  let ors = find.split("||");
  for (let or of ors) {
    let ands = or.split("&&");
    let apply = true;
    for (let and of ands) {
      if (and.startsWith("!")) {
        apply &= !rawText.includes(and.substr(1).replace(/\^/g, ""));
        continue;
      }
      if (and.includes(">>")) {
        apply &= rawText.includes(and.split(">>")[1].replace(/\^/g, ""));
        continue
      }
      apply &= rawText.includes(and.replace(/\^/g, ""));
    }
    if (apply) {
      for (let i = ands.length - 1; i >= 0; i--)
        if (!ands[i].startsWith("!"))
          return ands[i];
    }
  }
  return null;
}

const getConditionalPos = (rawText, rawJson, find, topLimit = 0, bottomLimit = Number.MAX_SAFE_INTEGER) =>
{
  let conditionalText = getConditionalText(rawText, find)
  if (conditionalText)
    return getTextPos(conditionalText, rawJson, topLimit, bottomLimit)
  return null;
}

const getTextPos = (text, jsonRaw, topLimit = 0, bottomLimit = Number.MAX_SAFE_INTEGER) =>
{
  const splitChars = (arr, c) =>
  {
    for (let pos = 0; pos < arr.length; pos++) {
      if (!arr[pos].includes(c) || arr[pos] == c) continue
      let item = arr[pos]
      arr.splice(pos, 1)
      let current = ""
      for (let i = item.length - 1; i >= 0; i--) {
        if (item[i] == c) {
          if (current != "")
            arr.splice(pos, 0, current)
          arr.splice(pos, 0, c)
          current = ""
        } else current = item[i] + current
      }
      if (current != "")
        arr.splice(pos, 0, current)
      pos = 0
    }
    return arr;
  }

  const compareIniEnd = (jsonR, word, pos) =>
    compareInitial(jsonR, word, pos) && compareEnd(jsonR, word, pos)

  const compareInitial = (jsonR, word, pos) =>
  {
    let item = jsonR[pos]
    if (word.replace(/\^/g,"") != item.text) return false
    if (!word.startsWith("^")) return true
    if (pos == 0) return true
    let prevItem = jsonR[pos-1]
    return Math.abs(prevItem.y - item.y) > 10
  }

  const compareEnd = (jsonR, word, pos) =>
  {
    let item = jsonR[pos]
    if (word.replace(/\^/g,"") != item.text) return false
    if (!word.endsWith("^")) return true
    if (pos == jsonR.length - 1) return true
    let nextItem = jsonR[pos+1]
    return Math.abs(item.y - nextItem.y) > 10
  }


  if (text.includes(">>")) {
    let pairs = text.split(">>")
    let iniTextPos = getTextPos(pairs[0], jsonRaw, 0, bottomLimit);
    if (iniTextPos == null) return null;
    return getTextPos(pairs[1], jsonRaw, iniTextPos.y, bottomLimit);
  }

  let words = text.replace(/\n/g, "").split(" ");
  words = splitChars(words, ",")
  words = splitChars(words, ".")
  words = splitChars(words, "(")
  words = splitChars(words, ")")

  let jsonR = JSON.parse(jsonRaw)
  for (let j = 0; j < jsonR.length; j++) {
    let count = 0;
    if (jsonR[j].text == words[0] || compareIniEnd(jsonR, words[0], j))
      for (let i = j; i < jsonR.length && i - j < words.length; i++)
        if (jsonR[i].text == words[i - j]  || compareIniEnd(jsonR, words[i-j], i))
          count++;
    if (count == words.length &&
      jsonR[j].y >= topLimit && jsonR[j].y <= bottomLimit)
      return {
        x: jsonR[j].x,
        y: jsonR[j].y
      }
  }
  return null;
}

export { getRegions, getConditionalText }