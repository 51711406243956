import * as df from 'dateformat';

const buildQuery = (query, search) =>
{
  if (search.search != null)
    query += ` AND documents.pages.regions.cells.value ILIKE ${search.search}`;
  if (search.clients != null) {
    query += " AND (";
    for (let client of search.clients)
      query += ` client=${client} OR`
    if (query.endsWith("OR"))
      query = query.substring(0, query.length - 3);
    query += ")"
  }
  if (search.banks != null) {
    query += " AND (";
    for (let bank of search.banks)
      query += ` documents.pages.regions.table.bank=${bank} OR`
    if (query.endsWith("OR"))
      query = query.substring(0, query.length - 3);
    query += ")"
  }
  if (search.iniDate != null)
    query += ` AND referenceDate >= ${df(search.iniDate, "yyyy-mm-dd HH:MM:ss")}`;
  if (search.endDate != null)
    query += ` AND referenceDate <= ${df(search.endDate, "yyyy-mm-dd HH:MM:ss")}`;
  return query;
}

export { buildQuery }