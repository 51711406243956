import React from 'react';
import cx from 'classnames';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import Info from 'components/declarations/Info';
import styles from 'styles/css/declarations/Details.module.css';

function Details(props)
{
  const { path, url } = props.match;

  return (
    <div className={styles.module}>

      <div className={styles.cardNav}>
        <NavLink to={`${url}/info`} activeClassName={styles.activeLink}>
          <button className={cx(styles.item, styles.col4Full)}>Información general</button>
        </NavLink>
      </div>

      <div className={styles.cardReduce}>
        <Switch>
          <Route path={`${path}/info`}
            component={Info} />
          <Redirect to={`${url}/info`} />
        </Switch>
      </div>
    </div>
  );
}

export default Details;