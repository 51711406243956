import React, { useState } from 'react';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';
import { useSave, useQuery, useDetail, useSet } from 'seed/gql'
import { usePost } from 'seed/api';
import * as queries from 'seed/gql/queries'
import { Loading } from 'seed/helpers'
import { buildQuery } from 'components/declarations/List.lhelpers';
import styles from 'styles/css/declarations/Form.module.css';
import { yearFormat } from 'components/util/Format';


const TEAMS = `
{
  teams {
    name
  }
}
`

const DECLARATIONS = `
{
  declarations
  { 
    id,
    internalToken
    referenceDate
  }
}
`

function AdminForm(props) {
  const { declaration_id } = props.match.params;
  const userId = sessionStorage.getItem("id")
  const editMode = declaration_id != null;

  const qDeclaration = useDetail(queries.DECLARATION, declaration_id);
  const qTeams = useQuery(TEAMS, `members=${userId}`);
  const { search = {} } = props;
  let query = userId != 1 ? `team.members=${userId}` : "";
  query = buildQuery(query, search);
  const qDeclarations = useQuery(DECLARATIONS, query);

  const [cMigrate, qMigrate] = usePost("/movcashes/migrate_movcashes", {
    onCompleted: data => {
      window.location.href = "/"
    }
  });

  const [callSave] = useSave(queries.SAVE_DECLARATION, {
    onCompleted: data => {
      const declarationId = data.saveDeclaration.declaration.id
      const prevDeclarationId = localStorage.getItem("previousDeclarationId")
      const hasPrevMovcash = localStorage.getItem("hasPreviousMovcashes")
      localStorage.setItem("declarationId", declarationId)

      if (hasPrevMovcash == "true" && prevDeclarationId != "undefined")
        cMigrate({ declaration_id: declarationId, previous_declaration_id: prevDeclarationId })
      else
        window.location.href = "/"
    }
  })


  const [callSet] = useSet(queries.SET_DECLARATION, {
    onCompleted: () => props.history.go(-1)
  })

  const [checked, setChecked] = useState(false);

  if (qTeams.loading || qDeclarations.loading) return <Loading />;
  if (qTeams.error || qDeclarations.error) return `Error!`;



  const { teams = [] } = qTeams.data;
  let { declarations: prev_declarations = [] } = qDeclarations.data
  prev_declarations
    = prev_declarations
      .filter(d => d.id != 1)
      .sort((a, b) => (new Date(b.referenceDate)).getTime() - (new Date(a.referenceDate)).getTime())

  let initialValues = {};
  if (editMode) {
    if (qDeclaration.loading || qDeclaration.data == null) return "Loading";
    if (qDeclaration.error) return "Error...";
    const declaration = JSON.parse(JSON.stringify(qDeclaration.data.declaration))
    initialValues = declaration;
    initialValues.year = (new Date(declaration.referenceDate)).getFullYear();
  } else {
    initialValues.year = (new Date()).getFullYear() - 1
  }

  const onSubmit = (values) => {
    const date = new Date();
    date.setFullYear(values.year);
    values.referenceDate = date
    values.team.id = parseInt(values.team.id)
    if (editMode) {
      values.id = parseInt(declaration_id);
      delete values.client;
      callSet(values)
    } else {
      localStorage.setItem("hasPreviousMovcashes", true)
      localStorage.setItem("previousDeclarationId", values.prev_declaration)
      callSave(values)
    }

  }

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  let checkedd = true;

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4>{declaration_id == null ? "Nueva declaración" : "Editar declaración"}</h4>
      </div>

      <div className={cx(styles.paper, styles.flexCol)}>
        <div className={cx(styles.modalDeclaration, styles.flexCol)}>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {() => (
              <Form>
                <div className={cx(styles.flex, styles.jump)}>
                  <div className={cx(styles.flexFill, styles.marginRight)}>
                    <label>Nombre del cliente</label>
                    <Field name="clientName" type="text" placeholder="" className={cx(styles.input)} required={true} />
                  </div>

                </div>
                <div className={cx(styles.flex, styles.jump)}>
                  <div className={styles.col6}>
                    <label>Código de referencia</label>
                    <Field name="internalToken" type="text" placeholder="Ej. MTY12" className={cx(styles.input)} required={true} />
                  </div>
                  <div className={styles.col6}>
                    <label>Año de ejercicio</label>
                    <Field name="year" type="number" className={cx(styles.dateInput)} required={true} />
                  </div>
                </div>
                <Field component="textarea" name="description" id="description" placeholder="Descripción (opcional)" className={styles.descripción} ></Field>

                <div className={cx(styles.flex, styles.jump)}>
                  <div className={styles.flexFill}>
                    <Field component="select" name="team.id"
                      className={styles.select} required={true} >
                      <option value="">Selecciona un equipo</option>
                      {teams.map((e, idx) => <option key={e.id} value={e.id}>{e.name}</option>)}
                    </Field>
                  </div>
                </div>

                <div className={cx(styles.flex, styles.jump)}>
                  <div className='row'>
                    <div className='col-1'>
                      <Field
                        name="show_prev_declaration"
                        id="prev_declaration_checkbox"
                        type="checkbox"
                        checked={checked}
                        onChange={handleCheckboxChange} // {checkedd = !checkedd}
                      />
                    </div>
                    <div className='col-11' style={{ fontStyle: "italic" }}>
                      Agregar histórico de ventas/ compras de acciones y bonos de declaración pasada
                    </div>
                  </div>
                </div>
                {
                  checked && (<div className={cx(styles.flex, styles.jump)}>
                    <div className={styles.flexFill}>
                      <Field component="select" name="prev_declaration"
                        className={styles.select} required={false} >
                        <option value="">Selecciona una declaración previa</option>
                        {prev_declarations.map(
                          (prev_declaration, idx) =>
                            <option key={prev_declaration.id} value={prev_declaration.id}>{prev_declaration.internalToken + " - " + yearFormat(prev_declaration.referenceDate)}</option>
                        )
                        }
                      </Field>
                    </div>
                  </div>
                  )
                }
                <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)} style={{ marginTop: "10px" }}>
                  <span className={styles.text}>Guardar</span>
                </button>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </div >
  );
}

export default AdminForm;