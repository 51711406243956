import React from 'react';
import cx from 'classnames';
import { Route, Link, Redirect, Switch } from 'react-router-dom';
import { Modal } from 'seed/helpers';
import DeclarationForm from 'components/declarations/Form';
import Details from 'components/declarations/Details';
import List from 'components/declarations/List';
import styles from 'styles/css/declarations/Declarations.module.css';


function Declarations(props)
{
  const { path, url } = props.match;

  const FormModal = props =>
    <Modal width={600} height={550}{...props} animation={""} >
      <DeclarationForm {...props} />
    </Modal>
  
  const DetailsModal = props =>
    <Modal width={700} height={550}{...props} animation={""} onClose={() => props.history.push(`${url}/${props.match.params.declaration_id}`)}>
      <Details {...props} />
    </Modal>

  return (
    <div className={styles.module}>
      <div className={styles.cardHeader}>
        <h5 className={styles.title}>Declaraciones</h5>
        <Link to={`${url}/new`}
          className={cx(styles.btn, styles.btnOutlineGreen, styles.toRigth)}>
          <span className={styles.text}>Registrar</span>
        </Link>
      </div>
      <Route path={`${path}`}
        component={List}></Route>
      <Route path={[`${path}/new`]}
        component={FormModal} />
      <Route path={[`${path}/:declaration_id(\\d+)/details`]}
        component={DetailsModal} />
    </div>
  )
}

export default Declarations;