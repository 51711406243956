import React from 'react';
import * as queries from 'seed/gql/queries'
import { useDetail, useDelete } from 'seed/gql'
import { yearFormat } from 'components/util/Format'
import { Route, Link } from 'react-router-dom'
import { Modal }  from 'seed/helpers'
import DeclarationForm from 'components/declarations/Form';
import { Loading } from 'seed/helpers'
import styles from 'styles/css/declarations/Details.module.css';


const DECLARATION = `
{
  declaration
  { 
    internalToken
    portalToken
    referenceDate
    description
    clientName
    team {
      name
      members {
        firstName
        lastName
        email
        rol
      }
    }
  }
}
`

function Details(props)
{
  const { declaration_id } = props.match.params;
  const { path, url } = props.match;
  const role = sessionStorage.getItem("role");
  const isAdmin = true;


  const Form = props =>
    <Modal width={600} height={550}{...props} animation={""} >
      <DeclarationForm {...props} />
    </Modal>

  const qDeclaration = useDetail(DECLARATION, declaration_id);
  const [callDelete] = useDelete(queries.DELETE_DECLARATION, {
    onCompleted: () => props.history.go(-1)
  });
  if (qDeclaration.loading) return <Loading />;
  if (qDeclaration.error) return `Error! ${qDeclaration.message}`;

  const onDelete = () =>
  {
    if (window.confirm("¿Estás seguro?, Se eliminarán todos los documentos, registros y cálculos de esta declaración"))
      callDelete({ id: parseInt(declaration_id) });
  }

  const { declaration = {} } = qDeclaration.data;
  let members = [...declaration.team.members]
  members.sort((m1,m2) => m1.rol == "ASSOCIATE" ? -1 : 1)

  return (
    <div className={styles.module}>
      <div className={styles.details}>
        <h3>{declaration.clientName}</h3>
        <h5>{yearFormat(declaration.referenceDate)}</h5>
        {isAdmin ?
          <div>
            <Link to={`${url}/edit`}>Editar</Link> | <a href="javascript:void(0)" onClick={onDelete}>Eliminar</a>
          </div> : null}
        <hr />
        <p>
          <b>Código de referencia: </b><br />
          {declaration.internalToken}
        </p>
        <p>
          <b>Descripción: </b><br />
          {declaration.description}
        </p>
        <hr />
        <p>
          <b>Equipo - {declaration.team.name}</b>
          <ul>
            {members.map((m, idx) =>
              <li key={idx}>
                <b>-</b> {m.firstName} {m.lastName} (<a href={"mailto:" + m.email}>{m.email}</a>)
                </li>)
            }
          </ul>
        </p>
      </div>
      <Route path={[`${path}/edit`]}
        component={Form} />
    </div>
  )
}

export default Details;