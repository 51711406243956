import React, { useState } from 'react';
import cx from 'classnames';
import $ from 'jquery';
import { Link, Route } from 'react-router-dom';
import { useCount, useQuery } from 'seed/gql';
import { usePost } from 'seed/api';
import { screenSize } from 'components/util/Format';
import Upload from 'components/transactions/Upload';
import { Modal } from 'seed/helpers';
import Table from 'components/transactions/Table';
import TableScan from 'components/scan/table/Table';
import { Loading } from 'seed/helpers';
import styles from 'styles/css/transactions/Transactions.module.css';

const MOVCASHES = `
{
  movcashes {
    accountNumber
  }    
}
`

function Transactions(props) {

  const { path, url } = props.match;
  const { declaration_id } = props.match.params;
  const defPage = localStorage.getItem("page_emptying") ? parseInt(localStorage.getItem("page_emptying")) : 1
  const [pageNum, setPageNum] = useState(defPage);
  const [filter, setFilter] = useState("")
  const qCount = useCount("movcash", `declaration=${declaration_id}`
    + (filter != "" ? ` AND accountNumber=${filter}` : ""))
  const qMovcashes = useQuery(MOVCASHES, `declaration=${declaration_id}`)
  const [callSync, qSync] = usePost(`/movcashes/sync`,
    {
      onCompleted: () => {
        localStorage.setItem("page_emptying", 1)
        window.location.reload()
      }
    })


  if (qCount.loading) return <Loading />

  const count = qCount.data ? qCount.data.movcashCount.count : 0;
  const width = $(window).width();
  const height = $(window).height();

  let { movcashes = [] } = qMovcashes.data;
  let movcashesSorted = [...movcashes]
  movcashesSorted.sort((m1, m2) => m1.accountNumber.localeCompare(m2.accountNumber))
  const accountL = {}
  const accounts = movcashesSorted.map(mc => {
    accountL[mc.accountNumber] = true;
    return mc.accountNumber;
  }).filter(account => {
    const unique = accountL[account] == true;
    accountL[account] = false;
    return unique;
  })

  const onClickPage = i => {
    setPageNum(i);
    localStorage.setItem("page_emptying", i)
  }

  const onChangeFilter = e => {
    setFilter(e.target.value);
    setPageNum(1);
  }

  const onClickSync = () =>
    callSync({ declaration_id: declaration_id })


  const pageSize = 150;
  const pageCount = Math.ceil(count / pageSize);
  let pages = [];
  if (pageCount < defPage && pageCount > 0) onClickPage(1);

  for (let i = 1; i <= pageCount; i++)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(i)}
        style={{ background: pageNum == i ? "#f5f5f5" : "#fff" }}>{i}</div>)

  const UploadModal = props =>
    <Modal width={450} height={470}{...props} animation={""}>
      <Upload {...Object.assign({}, props, {accountNumber: filter, onlyDownload: false})}  />
    </Modal>

  const TableModal = props =>
    <Modal width={width - 40} height={height - 40} animation="" {...props} >
      <TableScan {...props} />
    </Modal>


  return (
    <div className={styles.module}>

      <div className={styles.header}>
        <select value={filter} onChange={onChangeFilter}>
          <option value="">Todas las cuentas</option>
          {
            accounts.map(ac =>
              <option value={ac}>{ac}</option>)
          }
        </select>
          
        {/*
        <button className={cx(styles.sync, styles.btn)}
          onClick={onClickSync}>
          {!qSync.loading ? "Sincronizar" : "Sincronizando ..."}
          <i className="fas fa-sync"></i>
        </button>
        */}
        <Link to={`${url}/correct`} className={cx(styles.btn, styles.btnGreen, styles.csv)}>
          Modificar Movimientos<i className="fas fa-upload"></i>
        </Link>
      </div>

      <div className={styles.content}
        style={{ height: (screenSize() - 260) + "px" }}>
        <Table {...props} pageNum={pageNum}
          filter={filter} />
      </div>
      <div className={styles.pages}>
        {pages}
      </div>
      <Route path={`${path}/correct`}
        component={UploadModal} />
      <Route path={`${path}/:document_id(\\d+)/:table_id(\\d+)/:region_id(\\d+)/:row_idx(\\d+)`}
        component={TableModal} />
    </div>
  );
}

export default Transactions;